import React from 'react';
import * as Select from '@radix-ui/react-select';
import { ChevronDownIcon, CheckIcon } from '@radix-ui/react-icons';
import classnames from 'classnames';
import InputError from './InputError';
import InputLabel from './InputLabel';

const InputDropdown = React.forwardRef((props) => {
    const { label, required, name, errors, options, hideLabel, placeholder, selectedValue } = props;

    const selectOptions = options.map(option => {
        return <SelectItem key={`${name}-${option.value.toString()}`} value={option.value.toString()}>{option.label.toString()}</SelectItem>
    });

    return (
        <div className="flex flex-col p-2 w-full">
            <InputLabel display={{ base: `${hideLabel ? 'none' : 'block'}`, sm: 'block' }} _astrick={{ color: `${hideLabel ? 'white' : 'blue.dark'}` }}>
                {label} {required && <span className="text-[#B80F00]">*</span>}
            </InputLabel>
            <Select.Root {...props} value={selectedValue}>
                <Select.Trigger
                    className={`data-[placeholder]:text-[#A9A9AC] h-[41px] flex w-full justify-between items-center border-2 ${name in errors ? 'input--error' : 'border-[#13187C]'} rounded-full p-2 pl-4 `}
                    aria-label={label}
                >
                    <Select.Value className={'text-sm'} aria-label={label} placeholder={placeholder} />
                    <Select.Icon>
                        <ChevronDownIcon />
                    </Select.Icon>
                </Select.Trigger>
                <Select.Portal>
                    <Select.Content className="overflow-hidden bg-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
                        <Select.Viewport className="p-[5px]">
                            <Select.Group>
                                {selectOptions}
                            </Select.Group>
                        </Select.Viewport>
                    </Select.Content>
                </Select.Portal>
            </Select.Root>
            {name in errors && <InputError>{errors[name]}</InputError>}
        </div>
    )
});

const SelectItem = React.forwardRef(({ children, className, ...props }, forwardedRef) => {
    return (
        <Select.Item
            className={classnames(
                'hover:bg-[#e6f1f8] py-4 leading-none text-[#13187C] rounded-[3px] flex items-center pr-[35px] pl-[30px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1',
                className
            )}
            {...props}
            ref={forwardedRef}
        >
            <Select.ItemText>{children}</Select.ItemText>
            <Select.ItemIndicator className="absolute cursor-pointer left-0 w-[25px] inline-flex items-center justify-center">
                <CheckIcon width={40} height={40} fill='#13187C' />
            </Select.ItemIndicator>
        </Select.Item>
    );
});

export default InputDropdown

