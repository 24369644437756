import React from 'react';

const ContentRight = ({ children }) => {
    return (
        <div className="px-2 sm:px-6 lg:px-0 py-8 lg:py-0 w-full lg:w-7/12">
            <div className='w-full md:max-w-[80%] lg:max-w-none mx-auto'>
                {children}
            </div>
        </div>

    )
};

export default ContentRight;
