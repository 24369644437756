import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import InputText from '../components/inputs/InputText';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Caption from '../components/typography/Caption';
import { isEmpty, isValidAge, isValidDate } from '../helpers';
import { checkReturnUser } from '../services/api/voter';
import { formLabels, formErrors, buttons } from '../content';
import Spinner from '../components/icons/Spinner';

const ReturnUserForm = ({ onSubmit }) => {
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const [searchParams, setSearchParams] = useSearchParams();
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [dateOfBirth, setDateOfBirth] = React.useState('');
    const [errors, setErrors] = React.useState({});
    const [submitting, setSubmitting] = React.useState(false);

    const debouncedSetFirstName = useDebouncedCallback(
        (value) => setFirstName(value),
        500
    );

    const debouncedSetLasName = useDebouncedCallback(
        (value) => setLastName(value),
        500
    );

    const debouncedSetDateOfBirth = useDebouncedCallback(
        (value) => setDateOfBirth(value),
        500
    );

    const isValid = () => {
        let checkErrors = {};

        if (isEmpty(firstName)) {
            checkErrors = {
                ...checkErrors,
                first_name: formErrors[currentLanguage].required_first_name,
            };
        } else if (firstName.length > 50) {
            checkErrors = {
                ...checkErrors,
                first_name: formErrors[currentLanguage].valid_first_name,
            };
        }

        if (isEmpty(lastName)) {
            checkErrors = {
                ...checkErrors,
                last_name: formErrors[currentLanguage].required_last_name,
            };
        } else if (lastName.length > 50) {
            checkErrors = {
                ...checkErrors,
                last_name: formErrors[currentLanguage].valid_last_name,
            };
        }

        if (isEmpty(dateOfBirth)) {
            checkErrors = {
                ...checkErrors,
                date_of_birth: formErrors[currentLanguage].required_date_of_birth,
            };
        } else if (!isValidDate(dateOfBirth)) {
            checkErrors = {
                ...checkErrors,
                date_of_birth: formErrors[currentLanguage].valid_date_of_birth,
            };
        } else if (!isValidAge(dateOfBirth)) {
            checkErrors = {
                ...checkErrors,
                date_of_birth: formErrors[currentLanguage].valid_date_of_birth,
            };
        }

        setErrors(checkErrors);

        if (!!Object.keys(checkErrors).length) {
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = async () => {
        if (!isValid()) {
            return;
        }

        const dobParts = dateOfBirth.split('/');

        const postData = {
            first_name: firstName,
            last_name: lastName,
            dob_month: dobParts[0],
            dob_day: dobParts[1],
            dob_year: dobParts[2],
            token: searchParams.get('token'),
            id: searchParams.get('id'),
        };

        try {
            setSubmitting(true);

            const response = await checkReturnUser(postData);

            if (response?.status === 'success') {
                let registrantInfo = {
                    ...response?.registration,
                    return_token: searchParams.get('token'),
                    return_id: searchParams.get('id'),
                };

                onSubmit({registrantInfo: registrantInfo, registrationStatus: true});
                setSubmitting(false);
            } else if (response?.status === 'error') {
                onSubmit({registrationStatus: false});
                setSubmitting(false);
            } else {
                alert('Sorry! Had trouble getting registration information.');
                //onSubmit({registrantInfo:  {...postData, email_address: emailAddress, phone_number: phoneNumber, phone_type: phoneType}, registrationStatus: null});
                setSubmitting(false);
            }
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <div className="relative">
            {submitting &&
                <div className="rounded-lg absolute w-full h-full justify-center items-center z-10 overflow-hidden">
                    <div className="rounded-lg bg-[#7395D4] absolute w-full h-full opacity-30"></div>
                    <Spinner />
                </div>
            }
            <div className="p-8">
                <div className="flex flex-row">
                    <div className="flex-1">
                        <InputText
                            label={formLabels[currentLanguage].first_name}
                            required
                            name="first_name"
                            errors={errors}
                            placeholder="Jane"
                            onChange={e => setFirstName(e.target.value)}
                            // onBlur={e => setFirstName(e.target.value)}
                            onFocus={() => {
                                setErrors(current => {
                                    const {first_name, ...newErrors} = current;
                                    return newErrors;
                                });
                            }}
                        />
                    </div>
                    <div className="flex-1">
                        <InputText
                            label={formLabels[currentLanguage].last_name}
                            required
                            name="last_name"
                            errors={errors}
                            placeholder="Doe"
                            onChange={e => setLastName(e.target.value)}
                            // onBlur={e => setLastName(e.target.value)}
                            onFocus={() => {
                                setErrors(current => {
                                    const {last_name, ...newErrors} = current;
                                    return newErrors;
                                });
                            }}
                        />
                    </div>
                </div>
                <div>
                    <InputText
                        label={formLabels[currentLanguage].date_of_birth}
                        required
                        name="date_of_birth"
                        errors={errors}
                        placeholder="mm/dd/yyyy"
                        maskType="dob"
                        onChange={e => setDateOfBirth(e.target.value)}
                        // onBlur={e => setDateOfBirth(e.target.value)}
                        onFocus={() => {
                            setErrors(current => {
                                const {date_of_birth, ...newErrors} = current;
                                return newErrors;
                            });
                        }}
                    />
                </div>
                <div className="px-2">
                    <Caption textAlign="right">
                    {formLabels[currentLanguage].required}
                    </Caption>
                    <div className="flex flex-col-reverse md:flex-row mt-8 items-center justify-end">
                        <div className="ml-4">
                            <PrimaryButton onClick={handleSubmit} title={buttons[currentLanguage].submit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ReturnUserForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default ReturnUserForm
