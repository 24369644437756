import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import InputText from '../components/inputs/InputText';
import CheckboxList from '../components/inputs/CheckboxList';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Caption from '../components/typography/Caption';
import { makeDate, isEmpty, isValidAge, isValidDate } from '../helpers';
import { formLabels, formErrors, buttons } from '../content';
import Spinner from '../components/icons/Spinner';

const content = {
    "en-US": {
        consent_text: "By checking this box, you are providing your consent to retain your date of birth for purposes of tracking your voter registration application.",
    },
    "es-ES": {
        consent_text: "Al marcar este cuadro, nos da su consentimiento para guardar su fecha de nacimiento para poder hacer un seguimiento de su solicitud de registro de votante.",
    },
};

const DateOfBirthConsentForm = ({ onSubmit }) => {
    const registrant = useSelector((state) => state.registrant);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const [dateOfBirth, setDateOfBirth] = React.useState('');
    const [checkboxValues, setCheckboxValues] = React.useState([]);
    const [errors, setErrors] = React.useState({});
    const [submitting, setSubmitting] = React.useState(false);

    const debouncedSetDateOfBirth = useDebouncedCallback(
        (value) => setDateOfBirth(value),
        500
    );

    React.useEffect(() => {
        setDateOfBirth(makeDate(registrant.check_date_of_birth_month, registrant.check_date_of_birth_day, registrant.check_date_of_birth_year));
        setCheckboxValues([
            registrant.is_dob_consented ? 'is_dob_consented' : null,
        ].filter(i => i));
    }, [registrant]);

    React.useEffect(() => {
        setDateOfBirth(makeDate(registrant.check_date_of_birth_month, registrant.check_date_of_birth_day, registrant.check_date_of_birth_year));
        setCheckboxValues([
            registrant.is_dob_consented ? 'is_dob_consented' : null,
        ].filter(i => i));
    }, []);

    const isValid = () => {
        let checkErrors = {};

        if (isEmpty(dateOfBirth)) {
            checkErrors = {
                ...checkErrors,
                date_of_birth: formErrors[currentLanguage].valid_date_of_birth,
            };
        } else if (!isValidDate(dateOfBirth)) {
            checkErrors = {
                ...checkErrors,
                date_of_birth: formErrors[currentLanguage].valid_date_of_birth,
            };
        } else if (!isValidAge(dateOfBirth)) {
            checkErrors = {
                ...checkErrors,
                date_of_birth: formErrors[currentLanguage].valid_date_of_birth,
            };
        }

        setErrors(checkErrors);

        if (!!Object.keys(checkErrors).length) {
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = async () => {
        if (!isValid()) {
            return;
        }

        try {
            setSubmitting(true);

            const dobParts = dateOfBirth.split('/');
            const postData = {
                is_dob_consented: checkboxValues.includes('is_dob_consented') || false,
                dob_month: dobParts[0],
                dob_day: dobParts[1],
                dob_year: dobParts[2],
            };
            
            onSubmit(postData);
            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <div className="relative">
            {submitting &&
                <div className="absolute z-10 items-center justify-center w-full h-full overflow-hidden rounded-lg">
                    <div className="rounded-lg bg-[#7395D4] absolute w-full h-full opacity-30"></div>
                    <Spinner />
                </div>
            }
            <form noValidate>
                <div>
                    <InputText
                        label={formLabels[currentLanguage].date_of_birth}
                        required
                        name="date_of_birth"
                        errors={errors}
                        placeholder="mm/dd/yyyy"
                        maskType="dob"
                        value={dateOfBirth}
                        onChange={e => setDateOfBirth(e.target.value)}
                        onFocus={() => {
                            const newErrors = delete errors.date_of_birth;
                            setErrors({
                                ...newErrors
                            })
                        }}
                    />
                </div>
                <div className="py-4 pl-4">
                    <CheckboxList value={checkboxValues} onChange={values => {
                        setCheckboxValues(values)}} width="100%" items={[
                        { label: content[currentLanguage].consent_text, value: 'is_dob_consented' },
                    ]} />
                </div>
                <div className="px-2">
                    <Caption textAlign="right">
                        {formLabels[currentLanguage].required}
                    </Caption>
                    <div className="flex flex-col-reverse items-end justify-end mt-8 sm:flex-row sm:items-center">
                        {/*<div>
                            <TextButton onClick={() => navigate('/opt-in', { state: { registrationType: 'not_eligible' }})} title="I am ineligible to vote" />
                        </div>*/}
                        <div className="ml-4">
                            <PrimaryButton onClick={handleSubmit} title={buttons[currentLanguage].next_step} />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

DateOfBirthConsentForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default DateOfBirthConsentForm;
