import { configureStore } from '@reduxjs/toolkit';
import partnerReducer from './partner/partnerSlice';
import registrantReducer from './registrant/registrantSlice';
import settingsReducer from './settings/settingsSlice';
import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';

const rootReducer = combineReducers({
    partner: partnerReducer,
    registrant: registrantReducer,
    settings: settingsReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: [
        createLogger()
    ]
});

export { store };
