import * as React from 'react';
import Wrapper from "../components/layout/Wrapper";
import Heading1 from '../components/typography/Heading1';
import Paragraph from '../components/typography/Paragraph';
import PrimaryButton from '../components/buttons/PrimaryButton';
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import { changeStep } from "../store/settings/settingsSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { buttons } from '../content';

export const content = {
    "en-US": {
        heading: "In order to get you registered, you'll need to update your name with SSA.",
        paragraph_1: "Please contact your local Social Security office to learn next steps. If you have reached this screen in error, please go back in the process or start a new registration.",
    },
    "es-ES": {
        heading: "Para registrarse, debe actualizar su nombre en la SSA.",
        paragraph_1: "Póngase en contacto con su oficina local del Seguro Social para informarse sobre los pasos a seguir. Si llegó a esta pantalla por error, regrese en el proceso o comience un registro nuevo.",
    }
};

function NameNotUpdatedPage({ navigation }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);

    const nextStep = () => {
        navigate('/opt-in', { state: { registrationType: 'not_eligible' } });
    };

    React.useEffect(() => {
        dispatch(changeStep([2, 6]));
    }, []);

    return (
        <Wrapper>
            <ContentLeft>
                <Heading1 mb={4}>{content[currentLanguage].heading}</Heading1>
            </ContentLeft>
            <ContentRight>
                <div className="px-2 sm:px-2 lg:px-0x">
                    <Paragraph>{content[currentLanguage].paragraph_1}</Paragraph>
                    <div className="w-full mt-4 flex justify-end">
                        <PrimaryButton onClick={nextStep} title={buttons[currentLanguage].next_step} />
                    </div>
                </div>
            </ContentRight>
        </Wrapper>
    );
}

export default NameNotUpdatedPage;
