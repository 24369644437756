import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SignaturePad from "react-signature-canvas";
import Heading1 from "../components/typography/Heading1";
import Paragraph from "../components/typography/Paragraph";
import FormWrapper from "../components/layout/FormWrapper";
import PrimaryButton from "../components/buttons/PrimaryButton";
import TextButton from "../components/buttons/TextButton";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import Heading2 from "../components/typography/Heading2";
import ContentColumn from "../components/layout/ContentColumn";
import { changeStep } from "../store/settings/settingsSlice";
import { buttons } from '../content';

export const content = {
  "en-US": {
    heading: "Review the statement below and sign to verify your registration.",
    paragraph: "By signing below, I hereby certify that, to the best of my knowledge, I am a qualified elector, having resided at the above residential address for at least 28 consecutive days immediately preceding this election, that I have no present intent to move, and I have not voted in this election. I also certify that I am not otherwise disqualified from voting and that all statements on this form are true and correct. If I have provided false information, I may be subject to fine or imprisonment under State and Federal laws.",
    review_heading: "Review Your Signature:",
  },
  "es-ES": {
    heading: "Revise la siguiente afirmación y firme para verificar su registro.",
    paragraph: "Al firmar a continuación, por el presente certifico que, según mi leal saber y entender, soy un votante calificado, que ha residido en la dirección residencial mencionada arriba durante al menos 28 días consecutivos inmediatamente antes de esta elección, que no tengo actualmente intenciones de mudarme y que no he votado en esta elección. También certifico que no estoy descalificado de ninguna otra forma para votar y que todas las declaraciones en este formulario son verdaderas y correctas. Si he proporcionado información falsa, posiblemente sea sujeto de alguna multa o impresión conforme a las leyes estatales y federales",
    review_heading: "Revise su firma",
  }
};

function SignaturePage({ navigation }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const [canAdd, setCanAdd] = React.useState(false);
  const [signature, setSignature] = React.useState(null);
  const [errors, setErrors] = React.useState({});

  let sigPad = React.useRef();

  const handleSignatureConfirm = () => {
    if (sigPad.isEmpty()) {
      alert('Empty')
    } else {
      setSignature(
        sigPad
          .getCanvas()
          .toDataURL("image/png")
          .replace("data:image/png;base64,", "")
      );
    }
  };

  const startSignature = () => {
    setCanAdd(true);
  };

  const editSignature = () => {
    setSignature(null);
    setCanAdd(false);
  };

  const clearSignature = () => {
    sigPad.clear();
    setSignature(null);
    setCanAdd(false);
  };

  const nextStep = () => {
    dispatch(
      updateRegistrant({
        has_signature: true,
        signature_data: signature,
      })
    );

    navigate("/proof-of-residence-intro");
  };

  React.useEffect(() => {
    dispatch(changeStep([5, 6]));
  }, []);

  return (
    <>
      <ContentColumn>
        <ContentColumn.Header>
          <Heading1>{content[currentLanguage].heading}</Heading1>
        </ContentColumn.Header>
        <ContentColumn.Content>
          <FormWrapper>
            {!signature ? (
              <div className="p-8">
                <Paragraph>{content[currentLanguage].paragraph}</Paragraph>
                <div className="mt-4 flex-grow-0 flex-shrink-0 items-center justify-center w-full max-w-[720px] h-[170px] border-2 border-[#11156f] rounded-lg overflow-hidden mx-auto">
                  <div className="sigContainer">
                    <SignaturePad
                      canvasProps={{ className: "sigPad" }}
                      onBegin={() => startSignature()}
                      ref={(ref) => (sigPad = ref)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-8">
                <div>
                  <Heading2 centered textTransform={"uppercase"} color={"red"}>
                    {content[currentLanguage].review_heading}
                  </Heading2>
                </div>
                <div className="mt-4 flex-grow-0 flex-shrink-0 items-center justify-center w-full max-w-[720px] h-[170px] border-2 border-[#11156f] rounded-lg overflow-hidden mx-auto">
                  <img
                    className="w-full h-[170px] object-contain"
                    src={"data:image/png;base64," + signature}
                    alt="Signature"
                  />
                </div>
              </div>
            )}
          </FormWrapper>
          {!signature ? (
            <div className="flex flex-col items-center justify-center mt-4">
              <PrimaryButton
                onClick={handleSignatureConfirm}
                title={buttons[currentLanguage].add_signature}
                isDisabled={!canAdd}
              />
              <TextButton onClick={clearSignature} title={buttons[currentLanguage].clear_signature} isDisabled={!canAdd} />
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center mt-4">
              <PrimaryButton onClick={nextStep} title={buttons[currentLanguage].next_step} />
              <TextButton
                onClick={() => editSignature()}
                title={buttons[currentLanguage].edit_signature}
              />
            </div>
          )}
        </ContentColumn.Content>
      </ContentColumn>
    </>
  );
};

export default SignaturePage;
