import React from 'react';
import PropTypes from 'prop-types';

const HeadingExtraLarge = ({ children, ...props }) => {
    const classes = `font-heading text-[#13187C] font-black text-5xl`;
    return <h2 className={classes}>{children}</h2>
};

HeadingExtraLarge.propTypes = {
    children: PropTypes.node.isRequired
};

export default HeadingExtraLarge;
