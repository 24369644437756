
import React from 'react';
import PropTypes from 'prop-types';

const Caption = ({ children, centered, ...props }) => {
    const { fontStyle, textAlign, mb } = props;
    const classes = `${fontStyle ? 'italic' : ''} ${mb ? 'mb-4' : ''} font-body text-sm text-[#13187C] ${centered ? 'text-center' : 'text-left'} ${textAlign ? 'text-right' : ''} `;
    return <div className={classes}>{children}</div>
};

Caption.propTypes = {
    children: PropTypes.node.isRequired
}

export default Caption;

