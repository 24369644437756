export const makeDate = (month, day, year) => {
    if (month && day && year) {
        return `${month}/${day}/${year}`;
    }
    return '';
};

export const makePhoneNumber = (registrant) => {
    if (registrant.phone_number_area && registrant.phone_number) {
        return `${registrant.phone_number_area}-${String(registrant.phone_number).slice(0, 3)}-${String(registrant.phone_number).slice(3, 7)}`;
    }
    return '';
};

export const isEmpty = (value) => {
    if (value === undefined) return true;
    if (!value) return true;
    if (typeof value == 'string' && value.length <= 0) return true;
    return false;
};

export const isValidPhoneNumber = (value) => {
    if (value.match(/^[2-9][0-9]{2}\-[2-9][0-9]{2}\-[0-9]{4}$/) == null) {
        return false;
    } else {
        return true;
    }
};

export const isValidDate = (value) => {
    if (value.match(/^02\/(?:[01]\d|2\d)\/(?:19|20)(?:0[048]|[13579][26]|[2468][048])|(?:0[13578]|10|12)\/(?:[0-2]\d|3[01])\/(?:19|20)\d{2}|(?:0[469]|11)\/(?:[0-2]\d|30)\/(?:19|20)\d{2}|02\/(?:[0-1]\d|2[0-8])\/(?:19|20)\d{2}$/) == null) {
        return false;
    } else {
        return true;
    }
};

export const isValidZip = (value) => {
    if (value.match(/^\d{5}(?:[- ]?\d{4})?$/) == null) {
        return false;
    } else {
        return true;
    }
};

export const isValidAge = (value) => {
    let parts = value.split('/');
    let dob = new Date(parts[2], parts[0] - 1, parts[1]);
    let today = new Date();
    let age = Math.floor((today - dob) / 31557600000);  // milliseconds in year

    if (age < 17) {
        return false;
    } else {
        return true;
    }
};

export const isValidIDNumber = (value) => {
    if (value.replaceAll('-', '').match(/^[a-zA-Z]{1}[0-9]{3}[0-9]{4}[0-9]{4}[0-9]{2}$/) == null) {
        return false;
    } else {
        return true;
    }
};

export const isValidLastFourSSN = (value) => {
    if (value.match(/^[0-9]{4}$/) == null) {
        return false;
    } else {
        return true;
    }
};
