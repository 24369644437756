import * as React from 'react';
import Heading1 from '../components/typography/Heading1';
import RadioButtonList from '../components/inputs/RadioButtonList';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Paragraph from '../components/typography/Paragraph';
import MailingAddressForm from '../forms/MailingAddressForm';
import { useSelector, useDispatch } from 'react-redux';
import { updateRegistrant } from '../store/registrant/registrantSlice';
import { useNavigate } from 'react-router-dom';
import Wrapper from "../components/layout/Wrapper";
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import { buttons } from '../content';

export const content = {
  "en-US": {
      heading: "Is your mailing address the same as your residential address?",
      show_form_heading: "Please provide us with your mailing address.",
      show_form_paragraph: "We'll need your current mailing address to get you registered.",
      option_1: "Yes, my mailing and residential addresses are the same.",
      option_2: "No, I have a different mailing address.",
  },
  "es-ES": {
      heading: "¿Su dirección postal es la misma que su dirección de residencia?",
      show_form_heading: "Proporcione su dirección postal.",
      show_form_paragraph: "Necesitaremos su dirección postal actual para registrarlo.",
      option_1: "Sí, mi dirección postal y de residencia son las mismas.",
      option_2: "No, tengo una dirección postal diferente.",
  }
};

function MailingAddressPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const registrant = useSelector((state) => state.registrant);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  const [hasMailingAddress, setHasMailingAddress] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  
  const nextStep = () => {
    if (!hasMailingAddress) {
      navigate('/additional-questions');
    } else {
      setHasMailingAddress(true);
      setShowForm(true);
    }
  };

  const handleSubmit = (data) => {
    dispatch(updateRegistrant({
      ...data,
    }));
    navigate('/additional-questions');
  };

  React.useEffect(() => {
    dispatch(updateRegistrant({
      has_mailing_address: hasMailingAddress
    }));
  }, [hasMailingAddress]);

  React.useEffect(() => {
    setHasMailingAddress(registrant.has_mailing_address);
  }, []);

  return (
    <Wrapper>
      <ContentLeft>
        {!showForm &&

          <Heading1 mb={4}>{content[currentLanguage].heading}</Heading1>
        }
        {showForm &&
          <>
            <Heading1 mb={4}>{content[currentLanguage].show_form_heading}</Heading1>
            <Paragraph>{content[currentLanguage].show_form_paragraph}</Paragraph>
          </>
        }
      </ContentLeft>
      <ContentRight>
        {!showForm &&
          <>
            <RadioButtonList value={hasMailingAddress} onChange={value => setHasMailingAddress(value)} width="100%" items={[
              { label: content[currentLanguage].option_1, value: false, id: 'mailing-address-yes' },
              { label: content[currentLanguage].option_2, value: true, id: 'mailing-address-no' },
            ]} />
            <div className="flex justify-end w-full mt-8">
              <PrimaryButton onClick={nextStep} title={buttons[currentLanguage].next_step} />
            </div>
          </>
        }
        {showForm &&
          <MailingAddressForm onSubmit={handleSubmit} />
        }
      </ContentRight>
    </Wrapper>
  );
}

export default MailingAddressPage;
