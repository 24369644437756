import React from 'react';

const HeaderLogo = ({ partnerName, imageData }) => {
  return (
    <div className="h-[84px]">
      <a className="h-[84px] inline-block" href="/">
        <img
          className='h-full object-contain'
          alt={partnerName}
          title={partnerName}
          src={partnerName ? (imageData ? imageData : require('../../assets/logo.png')) : require('../../assets/logo.png')}
        />
      </a>
    </div>
  );
};

export default HeaderLogo;
