import React from 'react';

const ContentLeft = ({ children }) => {
    return (
        <div className="px-4 sm:px-8 lg:px-0 py-8 lg:py-0 bg-[#F4F7F9]  w-full lg:w-4/12" w={{ base: '100%', lg: `${4 / 12 * 100}%` }}>
            <div className="w-full md:max-w-[80%] lg:max-w-none mx-auto">
                {children}
            </div>
        </div>
    )
};

export default ContentLeft;
