
export const getPartnerInfo = () => {
    return fetch(`${process.env.REACT_APP_API_BASE}partner/info`, { method: 'POST', headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}, body: JSON.stringify({website_code: process.env.REACT_APP_PARTNER})})
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json()
        })
        .catch((error) => {
            console.log(error);
        });
}