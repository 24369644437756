import React from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { useSelector } from 'react-redux';
import InputText from '../components/inputs/InputText';
import InputDropdown from '../components/inputs/InputDropdown';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { makeDate, isEmpty, isValidAge, isValidDate, isValidIDNumber, isValidZip, isValidLastFourSSN } from '../helpers';
import { formLabels, formErrors, buttons } from '../content';
import Spinner from '../components/icons/Spinner';

const IDManualEntryForm = ({ onSubmit, ...props }) => {
    const registrant = useSelector((state) => state.registrant);
    const registeredStatus = useSelector((state) => state.registrant.registered_status);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [middleName, setMiddleName] = React.useState('');
    const [formSuffix, setFormSuffix] = React.useState('');
    const [dateOfBirth, setDateOfBirth] = React.useState('');
    const [idNumber, setIDNumber] = React.useState('');
    const [idExpirationDate, setIDExpirationDate] = React.useState('');
    const [address1, setAddress1] = React.useState('');
    const [address2Type, setAddress2Type] = React.useState('');
    const [address2Number, setAddress2Number] = React.useState('');
    const [formCity, setFormCity] = React.useState('');
    const [formState, setFormState] = React.useState('');
    const [formZipcode, setFormZipcode] = React.useState('');
    const [lastFourSSN, setLastFourSSN] = React.useState('');
    const [errors, setErrors] = React.useState({});
    const [submitting, setSubmitting] = React.useState(false);

    const {
        check_first_name,
        check_last_name,
        first_name,
        last_name,
        middle_name,
        suffix,
        wi_license_id,
        last_four_ssn,
        address_1,
        address_2_type,
        address_2_number,
        city,
        state,
        zipcode,
        check_date_of_birth_month,
        check_date_of_birth_day,
        check_date_of_birth_year,
        date_of_birth_month,
        date_of_birth_day,
        date_of_birth_year,
        wi_license_expiration_month,
        wi_license_expiration_day,
        wi_license_expiration_year,
        identity_type
    } = registrant;

    React.useEffect(() => {
        if (check_first_name) setFirstName(check_first_name);
        if (check_last_name) setLastName(check_last_name);
    }, [check_first_name, check_last_name]);

    React.useEffect(() => {
        if (check_date_of_birth_month && check_date_of_birth_day && check_date_of_birth_year) {
            const dob = makeDate(check_date_of_birth_month, check_date_of_birth_day, check_date_of_birth_year);
            setDateOfBirth(dob);
        }
    }, [check_date_of_birth_month, check_date_of_birth_day, check_date_of_birth_year]);

    React.useEffect(() => {
        if (first_name) setFirstName(first_name);
        if (last_name) setLastName(last_name);
        if (middle_name) setMiddleName(middle_name);
        if (suffix) setFormSuffix(suffix);
        if (wi_license_id) setIDNumber(wi_license_id);
        if (wi_license_expiration_month && wi_license_expiration_day && wi_license_expiration_year) {
            const expireDate = makeDate(wi_license_expiration_month, wi_license_expiration_day, wi_license_expiration_year);
            setIDExpirationDate(expireDate);
        }
        if (last_four_ssn) setLastFourSSN(last_four_ssn);
        if (date_of_birth_month && date_of_birth_day && date_of_birth_year) {
            const expireDate = makeDate(date_of_birth_month, date_of_birth_day, date_of_birth_year);
            setDateOfBirth(expireDate);
        }
        if (address_1) setAddress1(address_1);
        if (address_2_number) setAddress2Number(address_2_number);
        if (address_2_type) setAddress2Type(address_2_type);
        if (city) setFormCity(city);
        if (state) setFormState(state);
        if (zipcode) setFormZipcode(zipcode);
    }, []);

    React.useEffect(() => {
        if (first_name) setFirstName(first_name);
        if (last_name) setLastName(last_name);
        if (middle_name) setMiddleName(middle_name);
        if (suffix) setFormSuffix(suffix);
        if (wi_license_id) setIDNumber(wi_license_id);
        if (wi_license_expiration_month && wi_license_expiration_day && wi_license_expiration_year) {
            const expireDate = makeDate(wi_license_expiration_month, wi_license_expiration_day, wi_license_expiration_year);
            setIDExpirationDate(expireDate);
        }
        if (last_four_ssn) setLastFourSSN(last_four_ssn);
        if (date_of_birth_month && date_of_birth_day && date_of_birth_year) {
            const expireDate = makeDate(date_of_birth_month, date_of_birth_day, date_of_birth_year);
            setDateOfBirth(expireDate);
        }
        if (address_1) setAddress1(address_1);
        if (address_2_number) setAddress2Number(address_2_number);
        if (address_2_type) setAddress2Type(address_2_type);
        if (city) setFormCity(city);
        if (state) setFormState(state);
        if (zipcode) setFormZipcode(zipcode);
    }, [
        first_name,
        last_name,
        middle_name,
        suffix,
        wi_license_id,
        wi_license_expiration_month,
        wi_license_expiration_day,
        wi_license_expiration_year,
        last_four_ssn,
        date_of_birth_month,
        date_of_birth_day,
        date_of_birth_year,
        address_1,
        address_2_type,
        address_2_number,
        city,
        state,
        zipcode,
    ]);

    const debouncedSetFirstName = useDebouncedCallback(
        (value) => setFirstName(value),
        500
    );

    const debouncedSetLastName = useDebouncedCallback(
        (value) => setLastName(value),
        500
    );

    const debouncedSetMiddleName = useDebouncedCallback(
        (value) => setMiddleName(value),
        500
    );

    const debouncedSetDateOfBirth = useDebouncedCallback(
        (value) => setDateOfBirth(value),
        500
    );

    const debouncedSetLicenseID = useDebouncedCallback(
        (value) => setIDNumber(value),
        500
    );

    const debouncedSetIDExpirationDate = useDebouncedCallback(
        (value) => setIDExpirationDate(value),
        500
    );

    const debouncedSetLastFourSSN = useDebouncedCallback(
        (value) => setLastFourSSN(value),
        500
    );

    const debouncedSetAddress1 = useDebouncedCallback(
        (value) => setAddress1(value),
        500
    );

    const debouncedSetAddress2Number = useDebouncedCallback(
        (value) => setAddress2Number(value),
        500
    );

    const debouncedSetFormCity = useDebouncedCallback(
        (value) => setFormCity(value),
        500
    );

    const debouncedSetFormZipcode = useDebouncedCallback(
        (value) => setFormZipcode(value),
        500
    );

    const isValid = () => {
        let checkErrors = {};

        if (isEmpty(firstName)) {
            checkErrors = {
                ...checkErrors,
                first_name: formErrors[currentLanguage].required_first_name,
            };
        } else if (firstName.length > 50) {
            checkErrors = {
                ...checkErrors,
                first_name: formErrors[currentLanguage].valid_first_name,
            };
        }

        if (isEmpty(lastName)) {
            checkErrors = {
                ...checkErrors,
                last_name: formErrors[currentLanguage].required_last_name,
            };
        } else if (lastName.length > 50) {
            checkErrors = {
                ...checkErrors,
                last_name: formErrors[currentLanguage].valid_last_name,
            };
        }

        if (isEmpty(dateOfBirth)) {
            checkErrors = {
                ...checkErrors,
                date_of_birth: formErrors[currentLanguage].required_date_of_birth,
            };
        } else if (!isValidDate(dateOfBirth)) {
            checkErrors = {
                ...checkErrors,
                date_of_birth: formErrors[currentLanguage].valid_date_of_birth,
            };
        } else if (!isValidAge(dateOfBirth)) {
            checkErrors = {
                ...checkErrors,
                date_of_birth: formErrors[currentLanguage].valid_date_of_birth,
            };
        }

        if (identity_type === 'has_id') {
            if (isEmpty(idNumber)) {
                checkErrors = {
                    ...checkErrors,
                    wi_license_id: formErrors[currentLanguage].valid_id,
                };
            } else if (!isValidIDNumber(idNumber)) {
                checkErrors = {
                    ...checkErrors,
                    wi_license_id: formErrors[currentLanguage].valid_id,
                };
            }

            if (isEmpty(idExpirationDate)) {
                checkErrors = {
                    ...checkErrors,
                    id_expiration_date: formErrors[currentLanguage].valid_id_expiration,
                };
            } else if (!isValidDate(idExpirationDate)) {
                checkErrors = {
                    ...checkErrors,
                    id_expiration_date: formErrors[currentLanguage].valid_id_expiration,
                };
            }
        } else if (identity_type === 'has_ssn') {
            if (isEmpty(lastFourSSN)) {
                checkErrors = {
                    ...checkErrors,
                    last_four_ssn: formErrors[currentLanguage].required_ssn,
                };
            } else if (lastFourSSN.length !== 4 || !isValidLastFourSSN(lastFourSSN)) {
                checkErrors = {
                    ...checkErrors,
                    last_four_ssn: formErrors[currentLanguage].valid_ssn,
                };
            }
        }

        if (isEmpty(address1)) {
            checkErrors = {
                ...checkErrors,
                address_1: formErrors[currentLanguage].address_required,
            };
        } else if (address1.length > 255) {
            checkErrors = {
                ...checkErrors,
                address_1: formErrors[currentLanguage].address_required,
            };
        }

        if (isEmpty(address2Number) && !isEmpty(address2Type)) {
            checkErrors = {
                ...checkErrors,
                address_2_number: formErrors[currentLanguage].unit_number_required,
            };
        } else if (!isEmpty(address2Number) && address2Number.length > 15) {
            checkErrors = {
                ...checkErrors,
                address_2_number: formErrors[currentLanguage].unit_number_required,
            };
        }

        if (isEmpty(formCity)) {
            checkErrors = {
                ...checkErrors,
                city: formErrors[currentLanguage].city_required,
            };
        } else if (formCity.length > 50) {
            checkErrors = {
                ...checkErrors,
                city: formErrors[currentLanguage].city_required,
            };
        }

        if (isEmpty(formState)) {
            checkErrors = {
                ...checkErrors,
                state: formErrors[currentLanguage].state_required,
            };
        } else if (formState !== 'WI') {
            checkErrors = {
                ...checkErrors,
                state: formErrors[currentLanguage].state_is_wi,
            };
        }

        if (isEmpty(formZipcode)) {
            checkErrors = {
                ...checkErrors,
                zipcode: formErrors[currentLanguage].zipcode_required,
            };
        } else if (!isValidZip(formZipcode)) {
            checkErrors = {
                ...checkErrors,
                zipcode: formErrors[currentLanguage].zipcode_required,
            };
        } else if (formZipcode.length > 10) {
            checkErrors = {
                ...checkErrors,
                zipcode: formErrors[currentLanguage].zipcode_required,
            };
        }

        setErrors(checkErrors);

        if (!!Object.keys(checkErrors).length) {
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = async () => {
        if (!isValid()) {
            return;
        }

        try {
            setSubmitting(true);

            const [dobMonth, dobDay, dobYear] = dateOfBirth.split('/');
            const [expireMonth, expireDay, expireYear] = idExpirationDate.split('/');

            onSubmit({
                first_name: firstName,
                last_name: lastName,
                middle_name: middleName,
                suffix: formSuffix,
                wi_license_id: idNumber,
                address_1: address1,
                address_2_type: address2Type,
                address_2_number: address2Number,
                city: formCity,
                state: formState,
                zipcode: formZipcode,
                date_of_birth_month: dobMonth,
                date_of_birth_day: dobDay,
                date_of_birth_year: dobYear,
                wi_license_expiration_month: expireMonth,
                wi_license_expiration_day: expireDay,
                wi_license_expiration_year: expireYear,
                last_four_ssn: lastFourSSN,
            });

            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <div className="relative">
            {submitting &&
                <div className="rounded-lg absolute w-full h-full justify-center items-center z-10 overflow-hidden">
                    <div className="rounded-lg bg-[#7395D4] absolute w-full h-full opacity-30"></div>
                    <Spinner />
                </div>
            }
            <form noValidate>
                {(registeredStatus === 'unknown' || (!registrant.information_correct?.startsWith('update_') && !registrant.information_correct?.startsWith('correct'))) && (
                    <>
                        <div className="flex flex-col sm:flex-row">
                            <div className="flex-1">
                                <InputText
                                    label={formLabels[currentLanguage].first_name}
                                    required
                                    name="first_name"
                                    errors={errors}
                                    placeholder="Jane"
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                    onFocus={() => {
                                        setErrors(current => {
                                            const {first_name, ...newErrors} = current;
                                            return newErrors;
                                        });
                                    }}
                                />
                            </div>
                            <div className="flex-1">
                                <InputText
                                    label={formLabels[currentLanguage].last_name}
                                    required
                                    name="last_name"
                                    errors={errors}
                                    placeholder="Doe"
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                    onFocus={() => {
                                        setErrors(current => {
                                            const {last_name, ...newErrors} = current;
                                            return newErrors;
                                        });
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col sm:flex-row">
                            <div className="flex-1">
                                <InputText
                                    label={formLabels[currentLanguage].middle_name}
                                    name="middle_name"
                                    errors={errors}
                                    placeholder="Henry"
                                    value={middleName}
                                    onChange={e => setMiddleName(e.target.value)}
                                    onFocus={() => {
                                        setErrors(current => {
                                            const {middle_name, ...newErrors} = current;
                                            return newErrors;
                                        });
                                    }}
                                />
                            </div>
                            <div className="flex-1">
                                <InputDropdown
                                    name="suffix"
                                    label={formLabels[currentLanguage].suffix}
                                    errors={errors}
                                    placeholder={formLabels[currentLanguage].suffix_eg}
                                    options={[
                                        { value: "JR", label: "JR" },
                                        { value: "SR", label: "SR" },
                                        { value: "II", label: "II" },
                                        { value: "III", label: "III" },
                                        { value: "IV", label: "IV" },
                                        { value: "V", label: "V" },
                                        { value: "VI", label: "VI" },
                                        { value: "VII", label: "VII" },
                                    ]}
                                    onValueChange={value => {
                                        setErrors(current => {
                                            const {suffix, ...newErrors} = current;
                                            return newErrors;
                                        });
                                        setFormSuffix(value);
                                    }}
                                    selectedValue={formSuffix}
                                />
                            </div>
                        </div>

                        <div>
                            <InputText
                                label={formLabels[currentLanguage].date_of_birth}
                                required
                                name="date_of_birth"
                                errors={errors}
                                placeholder="mm/dd/yyyy"
                                maskType="dob"
                                value={dateOfBirth}
                                onChange={e => setDateOfBirth(e.target.value)}
                                onFocus={() => {
                                    setErrors(current => {
                                        const {date_of_birth, ...newErrors} = current;
                                        return newErrors;
                                    });
                                }}
                            />
                        </div>
                    </>
                )}

                {identity_type !== 'has_ssn' &&
                    <>
                        <div>
                            <InputText
                                label={formLabels[currentLanguage].wi_license_id}
                                required
                                name="wi_license_id"
                                errors={errors}
                                placeholder="S123-4567-6501-00"
                                maskType="id"
                                value={idNumber}
                                onChange={e => setIDNumber(e.target.value)}
                                onFocus={() => {
                                    setErrors(current => {
                                        const {wi_license_id, ...newErrors} = current;
                                        return newErrors;
                                    });
                                }}
                            />
                        </div>
                        <div>
                            <InputText
                                label={formLabels[currentLanguage].id_expiration_date}
                                required
                                name="id_expiration_date"
                                errors={errors}
                                placeholder="mm/dd/yyyy"
                                maskType="dob"
                                value={idExpirationDate}
                                onChange={e => setIDExpirationDate(e.target.value)}
                                onFocus={() => {
                                    setErrors(current => {
                                        const {id_expiration_date, ...newErrors} = current;
                                        return newErrors;
                                    });
                                }}
                            />
                        </div>
                    </>
                }

                {identity_type === 'has_ssn' &&
                    <div>
                        <InputText
                            label={formLabels[currentLanguage].last_four_ssn}
                            required
                            name="last_four_ssn"
                            errors={errors}
                            placeholder="1111"
                            maskType="ssn"
                            value={lastFourSSN}
                            onChange={e => setLastFourSSN(e.target.value)}
                            onFocus={() => {
                                setErrors(current => {
                                    const {last_four_ssn, ...newErrors} = current;
                                    return newErrors;
                                });
                            }}
                        />
                    </div>
                }

                {(registeredStatus == 'unknown' || (!registrant.information_correct?.startsWith('update_') && !registrant.information_correct?.startsWith('correct'))) && (
                    <>
                        <div>
                            <InputText
                                label={formLabels[currentLanguage].address_1}
                                required
                                name="address_1"
                                errors={errors}
                                placeholder="5555 Oak St."
                                value={address1}
                                onChange={e => setAddress1(e.target.value)}
                                onFocus={() => {
                                    setErrors(current => {
                                        const {address_1, ...newErrors} = current;
                                        return newErrors;
                                    });
                                }}
                            />
                        </div>

                        <div className="flex flex-col sm:flex-row">
                            <div className="flex-1">
                                <InputDropdown
                                    name="address_2_type"
                                    label={formLabels[currentLanguage].apt_type}
                                    errors={errors}
                                    placeholder={formLabels[currentLanguage].select_option}
                                    options={[
                                        { value: "APT", label: "APT" },
                                        { value: "UNIT", label: "UNIT" },
                                        { value: "RM", label: "RM" },
                                        { value: "SUITE", label: "SUITE" },
                                        { value: "BSMT", label: "BSMT" },
                                        { value: "BLDG", label: "BLDG" },
                                        { value: "DEPT", label: "DEPT" },
                                        { value: "FL", label: "FL" },
                                        { value: "FRNT", label: "FRNT" },
                                        { value: "HNGR", label: "HNGR" },
                                        { value: "KEY", label: "KEY" },
                                        { value: "LBBY", label: "LBBY" },
                                        { value: "LOT", label: "LOT" },
                                        { value: "LOWR", label: "LOWR" },
                                        { value: "OFC", label: "OFC" },
                                        { value: "PH", label: "PH" },
                                        { value: "PIER", label: "PIER" },
                                        { value: "SIDE", label: "SIDE" },
                                        { value: "REAR", label: "REAR" },
                                        { value: "SLIP", label: "SLIP" },
                                        { value: "SPC", label: "SPC" },
                                        { value: "STOP", label: "STOP" },
                                        { value: "UPPR", label: "UPPR" },
                                        { value: "TRLR", label: "TRLR" },
                                        { value: "BOX", label: "BOX" },
                                    ]}
                                    onValueChange={value => {
                                        setErrors(current => {
                                            const {address_2_type, ...newErrors} = current;
                                            return newErrors;
                                        });
                                        setAddress2Type(value);
                                    }}
                                    selectedValue={address2Type}
                                />
                            </div>
                            <div className="flex-1">
                                <InputText
                                    label={formLabels[currentLanguage].apt_number}
                                    name="address_2_number"
                                    errors={errors}
                                    placeholder="40B"
                                    value={address2Number}
                                    onChange={e => setAddress2Number(e.target.value)}
                                    onFocus={() => {
                                        setErrors(current => {
                                            const {address_2_number, ...newErrors} = current;
                                            return newErrors;
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        
                        <div className="flex flex-col sm:flex-row">
                            <div className="flex-1">
                                <InputText
                                    label={formLabels[currentLanguage].city}
                                    name="city"
                                    errors={errors}
                                    placeholder="City"
                                    required
                                    value={formCity}
                                    onChange={e => setFormCity(e.target.value)}
                                    onFocus={() => {
                                        setErrors(current => {
                                            const {city, ...newErrors} = current;
                                            return newErrors;
                                        });
                                    }}
                                />
                            </div>
                            <div className="flex-1">
                            <InputDropdown
                                name="state"
                                label={formLabels[currentLanguage].state}
                                required
                                errors={errors}
                                placeholder={formLabels[currentLanguage].select_option}
                                options={[
                                    {
                                        label: "Alabama",
                                        value: "AL"
                                    },
                                    {
                                        label: "Alaska",
                                        value: "AK"
                                    },
                                    {
                                        label: "American Samoa",
                                        value: "AS"
                                    },
                                    {
                                        label: "Arizona",
                                        value: "AZ"
                                    },
                                    {
                                        label: "Arkansas",
                                        value: "AR"
                                    },
                                    {
                                        label: "California",
                                        value: "CA"
                                    },
                                    {
                                        label: "Colorado",
                                        value: "CO"
                                    },
                                    {
                                        label: "Connecticut",
                                        value: "CT"
                                    },
                                    {
                                        label: "Delaware",
                                        value: "DE"
                                    },
                                    {
                                        label: "District Of Columbia",
                                        value: "DC"
                                    },
                                    {
                                        label: "Federated States Of Micronesia",
                                        value: "FM"
                                    },
                                    {
                                        label: "Florida",
                                        value: "FL"
                                    },
                                    {
                                        label: "Georgia",
                                        value: "GA"
                                    },
                                    {
                                        label: "Guam",
                                        value: "GU"
                                    },
                                    {
                                        label: "Hawaii",
                                        value: "HI"
                                    },
                                    {
                                        label: "Idaho",
                                        value: "ID"
                                    },
                                    {
                                        label: "Illinois",
                                        value: "IL"
                                    },
                                    {
                                        label: "Indiana",
                                        value: "IN"
                                    },
                                    {
                                        label: "Iowa",
                                        value: "IA"
                                    },
                                    {
                                        label: "Kansas",
                                        value: "KS"
                                    },
                                    {
                                        label: "Kentucky",
                                        value: "KY"
                                    },
                                    {
                                        label: "Louisiana",
                                        value: "LA"
                                    },
                                    {
                                        label: "Maine",
                                        value: "ME"
                                    },
                                    {
                                        label: "Marshall Islands",
                                        value: "MH"
                                    },
                                    {
                                        label: "Maryland",
                                        value: "MD"
                                    },
                                    {
                                        label: "Massachusetts",
                                        value: "MA"
                                    },
                                    {
                                        label: "Michigan",
                                        value: "MI"
                                    },
                                    {
                                        label: "Minnesota",
                                        value: "MN"
                                    },
                                    {
                                        label: "Mississippi",
                                        value: "MS"
                                    },
                                    {
                                        label: "Missouri",
                                        value: "MO"
                                    },
                                    {
                                        label: "Montana",
                                        value: "MT"
                                    },
                                    {
                                        label: "Nebraska",
                                        value: "NE"
                                    },
                                    {
                                        label: "Nevada",
                                        value: "NV"
                                    },
                                    {
                                        label: "New Hampshire",
                                        value: "NH"
                                    },
                                    {
                                        label: "New Jersey",
                                        value: "NJ"
                                    },
                                    {
                                        label: "New Mexico",
                                        value: "NM"
                                    },
                                    {
                                        label: "New York",
                                        value: "NY"
                                    },
                                    {
                                        label: "North Carolina",
                                        value: "NC"
                                    },
                                    {
                                        label: "North Dakota",
                                        value: "ND"
                                    },
                                    {
                                        label: "Northern Mariana Islands",
                                        value: "MP"
                                    },
                                    {
                                        label: "Ohio",
                                        value: "OH"
                                    },
                                    {
                                        label: "Oklahoma",
                                        value: "OK"
                                    },
                                    {
                                        label: "Oregon",
                                        value: "OR"
                                    },
                                    {
                                        label: "Palau",
                                        value: "PW"
                                    },
                                    {
                                        label: "Pennsylvania",
                                        value: "PA"
                                    },
                                    {
                                        label: "Puerto Rico",
                                        value: "PR"
                                    },
                                    {
                                        label: "Rhode Island",
                                        value: "RI"
                                    },
                                    {
                                        label: "South Carolina",
                                        value: "SC"
                                    },
                                    {
                                        label: "South Dakota",
                                        value: "SD"
                                    },
                                    {
                                        label: "Tennessee",
                                        value: "TN"
                                    },
                                    {
                                        label: "Texas",
                                        value: "TX"
                                    },
                                    {
                                        label: "Utah",
                                        value: "UT"
                                    },
                                    {
                                        label: "Vermont",
                                        value: "VT"
                                    },
                                    {
                                        label: "Virgin Islands",
                                        value: "VI"
                                    },
                                    {
                                        label: "Virginia",
                                        value: "VA"
                                    },
                                    {
                                        label: "Washington",
                                        value: "WA"
                                    },
                                    {
                                        label: "West Virginia",
                                        value: "WV"
                                    },
                                    {
                                        label: "Wisconsin",
                                        value: "WI"
                                    },
                                    {
                                        label: "Wyoming",
                                        value: "WY"
                                    }
                                ]}
                                onValueChange={value => {
                                    setErrors(current => {
                                        const {state, ...newErrors} = current;
                                        return newErrors;
                                    });
                                    setFormState(value);
                                }}
                                selectedValue={formState}
                                // onSubmitEditing={handleSubmit}
                            />
                            </div>
                        </div>

                        <div className="flex flex-col sm:flex-row">
                            <div className="flex-1">
                                <InputText
                                    label={formLabels[currentLanguage].zipcode}
                                    name="zipcode"
                                    errors={errors}
                                    required
                                    placeholder="00000"
                                    maskType="zipcode"
                                    value={formZipcode}
                                    onChange={e => setFormZipcode(e.target.value)}
                                    onFocus={() => {
                                        setErrors(current => {
                                            const {zipcode, ...newErrors} = current;
                                            return newErrors;
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}

                <div className="px-1 mt-4">
                    <div className="flex flex-row mt-3 items-center justify-end">
                        <div className="ml-4">
                            <PrimaryButton onClick={handleSubmit} title={buttons[currentLanguage].next_step} />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

IDManualEntryForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default IDManualEntryForm;
