import * as React from "react";
import Heading1 from "../components/typography/Heading1";
import Paragraph from "../components/typography/Paragraph";
import MailInReview from "../components/layout/MailInReview";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ContentColumn from "../components/layout/ContentColumn";
import { changeStep } from "../store/settings/settingsSlice";

export const content = {
  "en-US": {
      heading: "Please confirm the information you provided.",
      paragraph: "This is an official State of Wisconsin Voter Registration Form. Please confirm that the information below is accurate. If information is incorrect, click on the edit icon at the top right corner of each section.",
  },
  "es-ES": {
    default_heading: "Confirme la información que proporcionó.",
    default_paragraph: "Este es un Formulario oficial de registro de votante del estado de Wisconsin. Si la información es incorrecta, dé clic en el ícono de editar en la esquina superior derecha de cada sección.",
  }
};

function ReviewPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  
  const nextStep = () => {
    navigate("/signature");
  };

  React.useEffect(() => {
    dispatch(changeStep([5, 6]));
  }, []);

  return (
    <ContentColumn>
      <ContentColumn.Header>
        <Heading1 mb={2}>{content[currentLanguage].heading}</Heading1>
        <Paragraph mb={2}>{content[currentLanguage].paragraph}{" "}</Paragraph>
      </ContentColumn.Header>
      <ContentColumn.Content>
        <MailInReview nextStep={nextStep} />
      </ContentColumn.Content>
    </ContentColumn>
  );
}

export default ReviewPage;
