import React from "react";
import Heading1 from '../components/typography/Heading1';
import PrimaryButton from "../components/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { clearCurrentRegistrant } from "../store/registrant/registrantSlice";
import { useDispatch, useSelector } from "react-redux";

export const content = {
  "en-US": {
    heading: "You're Registered",
  },
  "es-ES": {
    heading: "¡Está registrado!",
  }
}

const RegisteredPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  const startNew = () => {
    dispatch(clearCurrentRegistrant());
    navigate('/');
  };

  return (
    <div className="w-full max-w-[100%] md:max-w-[80%] py-6 mx-auto p-8">
      <Heading1>{content[currentLanguage].heading}</Heading1>
      <div className="flex flex-col items-center justify-end py-12">
        <PrimaryButton onClick={startNew} title="Check Another Voter Registration Status" />
      </div>
    </div>
  );
}

export default RegisteredPage;