import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: '',
    logo_image: '',
    logo_image_dark: '',
    can_register: false,
    can_register_online: false,
    can_register_offline: false,
    offline_disabled: false,
    no_register_online_message: '',
    no_register_offline_message: '',
    no_register_message: '',
    no_register_online_message_es: '',
    no_register_offline_message_es: '',
    no_register_message_es: '',
};


export const partnerSlice = createSlice({
    name: 'partner',
    initialState: initialState,
    reducers: {
        updatePartner: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
    },
});

export const {
    updatePartner,
} = partnerSlice.actions;

export default partnerSlice.reducer;
