import React from "react";
import { useSelector } from 'react-redux';
import Heading1 from '../components/typography/Heading1';
import Paragraph from '../components/typography/Paragraph';
import Wrapper from "../components/layout/Wrapper";
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";

function PrivacyPage() {
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);

    return (
        <Wrapper>
            <ContentLeft>
                <Heading1 mb={4}>
                    Privacy Policy
                </Heading1>
            </ContentLeft>
            <ContentRight>
                <div className="px-2 sm:px-2 lg:px-0">
                    <Paragraph>This Privacy Policy and the Terms of Use apply to use by organizations, canvassers and registrants of the See Bot Run custom application for registration to vote in the State of Wisconsin
                        (the "App").</Paragraph>

                    <Paragraph>The App is licensed by its owner See Bot Run ("SBR"), to certain organizations which are authorized to sublicense it to other organizations ("Organizations"), for use by their employees
                        and/or volunteers ("Canvassers") to register people to vote in Wisconsin ("Registrants").   SBR may modify this policy from time to time, so we encourage you to check this page when using the
                        App.  By using this App, you agree to the terms of this Privacy Policy and to the Terms of Use.</Paragraph>

                    <Paragraph mb={4} centered><strong className="underline">PRIVACY POLICY</strong></Paragraph>

                    <Paragraph><strong>For Registrants</strong></Paragraph>

                    <Paragraph>You may only enter information in the App to register to vote if you are a U.S. citizen and otherwise eligible to register, as indicated by prompts in the App.</Paragraph>

                    <Paragraph>The App will ask you to enter information about yourself that is ultimately submitted to your local clerk to register you to vote.  This information may include:</Paragraph>
                    <ul className="unordered-list">
                        <li>Name;</li>
                        <li>Date;</li>
                        <li>Residence location;</li>
                        <li>Location of previous residence immediately before moving to current residence location;</li>
                        <li>Citizenship;</li>
                        <li>Date of birth;</li>
                        <li>Age;</li>
                        <li>Number of a current and valid driver's license or the last 4 digits of your social security account number;</li>
                        <li>Whether you have resided within the ward or election district for 28 consecutive days;</li>
                        <li>Whether you have been convicted of a felony for which you have not been pardoned, and if so, whether you are incarcerated, or on parole, probation, or extended supervision;</li>
                        <li>Whether you are disqualified on any other ground from voting; and</li>
                        <li>Whether you are currently registered to vote at any other location</li>
                        <li>Certain forms of proof of residence if you do not have a valid driver's license or state id.</li>
                    </ul>

                    <Paragraph>The following information will only be conveyed to the election authorities and will NOT be retained from the voter registration application by the Canvasser or Organization for which they are working:</Paragraph>
                    <ul className="unordered-list">
                        <li>Date of birth</li>
                        <li>Driver's license number</li>
                        <li>Last four digits of your Social Security Number</li>
                        <li>Proof of residency</li>
                    </ul>

                    <Paragraph>Other information collected from you for the voter registration application may be retained by the Organization and: </Paragraph>
                    <ul className="unordered-list">
                        <li>Used by the Organization to contact you later with information about how, where and when to vote</li>
                        <li>Used by the Organization to contact you later to encourage you to vote in a coming election or elections.</li>
                        <li>Used together with information from other Registrants to analyze and study patterns in voter registration, the effectiveness of various voter registration methods and approaches
                            and characteristics of new registrants and may be provided to academic or research institutions or providers for those and similar purposes.</li>
                        <li>Provided to other nonpartisan organization engaged in voter registration and mobilization for the above purposes.</li>
                        <li>Used by See Bot Run and/or Organizations to maintain and improve the App.</li>
                        <li>Provided to state or local government agencies if and as required by applicable law.</li>
                    </ul>

                    <Paragraph>Your information will never be used, by any person or entity, for any other purpose and in particular, will never be used for any commercial purpose or to solicit
                        you to purchase any goods or services, or for any purpose prohibited by applicable law.</Paragraph>



                    <Paragraph><strong>For Canvassers</strong></Paragraph>

                    <Paragraph>Canvassers must use the App in accordance with the instructions and training they have been provided.</Paragraph>

                    <Paragraph>Canvassers may not personally retain any information of any kind collected from Registrants using the App. </Paragraph>

                    <Paragraph>Canvassers will be asked to enter certain information about themselves initially and each time they use the App to register people to vote.  This may include your
                        name and email and then, each time you use the App, your Partner Code, your username and password; the location at which you are attempting to register new voters each shift, date, time and
                        other information about the current shift.</Paragraph>

                    <Paragraph>Your credentials will be used solely to confirm that you are a Canvasser entitled to access the App. </Paragraph>

                    <Paragraph>The information you enter about each shift and the registrations collected during each shift may be:</Paragraph>

                    <ol className="ordered-list">
                        <li>Used by the Organization to track the progress of their voter registration programs, and shared with other nonpartisan, nonprofit organizations for similar purposes;</li>

                        <li>Used by the Organization to contact you for more information if needed about a particular registration or set of registrations you collected;</li>

                        <li>Used by the Organization or other organizations, in aggregate form, to study and analyze patterns in voter registrations, the effectiveness of voter
                            registration methods and approaches and characteristics of new registrants</li>

                        <li>Used by See Bot Run and/or Organizations to maintain and improve the App.</li>

                        <li>Provided to state or local government agencies if and as required by applicable law.</li>
                    </ol>





                    <Paragraph><strong>For Organizations</strong></Paragraph>

                    <Paragraph>The following information collected from Registrants may only be conveyed to the election authorities and CANNOT be retained by your organization:</Paragraph>
                    <ul className="unordered-list">
                        <li>Date of birth</li>
                        <li>Driver's license number</li>
                        <li>Last four digits of your Social Security Number</li>
                        <li>Proof of residency</li>
                    </ul>

                    <Paragraph>Other information collected through the App from Registrants may be retained by the Organization and:</Paragraph>
                    <ul className="unordered-list">
                        <li>Used by the Organization to contact the Registrants later with information about how, where and when to vote</li>
                        <li>Used by the Organization to contact Registrants later to encourage them to vote in a coming election or elections.</li>
                        <li>Used together with information from other Registrants to analyze and study patterns in voter registration, the effectiveness of various voter registration methods and
                            approaches and characteristics of new registrants and may be provided to academic or research institutions or providers for those and similar purposes.</li>
                        <li>Provided to other nonpartisan organizations engaged in voter registration and mobilization for the above purposes.</li>
                        <li>Used by See Bot Run and/or Organizations to maintain and improve the App.</li>
                        <li>Provided to state or local government agencies if and as required by applicable law.</li>
                    </ul>

                    <Paragraph>The Organization may never use any Registrant information for any other purpose and in particular, cannot use such information for any commercial purpose or
                        to solicit any Registrant to purchase any goods or services, or for any purpose prohibited by applicable law.</Paragraph>

                </div>
            </ContentRight>
        </Wrapper>
    );
}

export default PrivacyPage;