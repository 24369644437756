import React from 'react';
import { useSelector } from 'react-redux';

const ProgressBar = ({current, max}) => {
  const currentStep = useSelector((state) => state.settings.currentStep);

  return (
    <div className="flex flex-row px-1 w-full">
      {[...Array(currentStep[0])].map((x, i) =>
        <div className="flex-1 mx-1 h-[10px] bg-[#13187C] rounded-lg" key={i}></div> 
      )}
      {[...Array(currentStep[1] - currentStep[0])].map((x, i) =>
        <div className="flex-1 mx-1 h-[10px] bg-[#C1D0EC] rounded-lg" key={i}></div> 
      )}
    </div>
  );
};

export default ProgressBar;
