import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import InputText from '../components/inputs/InputText';
import InputDropdown from '../components/inputs/InputDropdown';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Spinner from '../components/icons/Spinner';
import CheckboxList from '../components/inputs/CheckboxList';
import Caption from '../components/typography/Caption';
import { checkRegistration } from '../services/api/voter';
import isEmail from 'validator/lib/isEmail';
import { isEmpty, isValidAge, isValidDate, isValidPhoneNumber } from '../helpers';
import { formLabels, formErrors, buttons } from '../content';

const CheckRegistrationForm = ({ onSubmit }) => {
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [dateOfBirth, setDateOfBirth] = React.useState('');
    const [emailAddress, setEmailAddress] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [phoneType, setPhoneType] = React.useState('cell');
    const [checkboxValues, setCheckboxValues] = React.useState([]);
    const [errors, setErrors] = React.useState({});
    const [submitting, setSubmitting] = React.useState(false);

    const debouncedSetFirstName = useDebouncedCallback(
        (value) => setFirstName(value),
        500
    );

    const debouncedSetLasName = useDebouncedCallback(
        (value) => setLastName(value),
        500
    );

    const debouncedSetDateOfBirth = useDebouncedCallback(
        (value) => setDateOfBirth(value),
        500
    );

    const debouncedSetEmailAddress = useDebouncedCallback(
        (value) => setEmailAddress(value),
        500
    );

    const debouncedSetPhoneNumber = useDebouncedCallback(
        (value) => setPhoneNumber(value),
        500
    );

    const isValid = () => {
        let checkErrors = {};

        if (isEmpty(firstName)) {
            checkErrors = {
                ...checkErrors,
                first_name: formErrors[currentLanguage].required_first_name,
            };
        } else if (firstName.length > 50) {
            checkErrors = {
                ...checkErrors,
                first_name: formErrors[currentLanguage].valid_first_name,
            };
        }

        if (isEmpty(lastName)) {
            checkErrors = {
                ...checkErrors,
                last_name: formErrors[currentLanguage].required_last_name,
            };
        } else if (lastName.length > 50) {
            checkErrors = {
                ...checkErrors,
                last_name: formErrors[currentLanguage].valid_last_name,
            };
        }

        if (isEmpty(dateOfBirth)) {
            checkErrors = {
                ...checkErrors,
                date_of_birth: formErrors[currentLanguage].required_date_of_birth,
            };
        } else if (!isValidDate(dateOfBirth)) {
            checkErrors = {
                ...checkErrors,
                date_of_birth: formErrors[currentLanguage].valid_date_of_birth,
            };
        } else if (!isValidAge(dateOfBirth)) {
            checkErrors = {
                ...checkErrors,
                date_of_birth: formErrors[currentLanguage].valid_date_of_birth,
            };
        }

        if (!isEmpty(emailAddress) && !isEmail(emailAddress)) {
            checkErrors = {
                ...checkErrors,
                email_address: formErrors[currentLanguage].required_email,
            };
        } else if (emailAddress.length > 100) {
            checkErrors = {
                ...checkErrors,
                email_address: formErrors[currentLanguage].valid_email,
            };
        }

        if (!isEmpty(phoneNumber.replace('-', '').replace('_', '')) && !isValidPhoneNumber(phoneNumber)) {
            checkErrors = {
                ...checkErrors,
                phone_number: formErrors[currentLanguage].valid_phone_number,
            };
        }

        setErrors(checkErrors);

        if (!!Object.keys(checkErrors).length) {
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = async () => {
        if (!isValid()) {
            return;
        }

        const dobParts = dateOfBirth.split('/');

        const postData = {
            first_name: firstName,
            last_name: lastName,
            dob_month: dobParts[0],
            dob_day: dobParts[1],
            dob_year: dobParts[2],
        };

        try {
            setSubmitting(true);
            const regStatus = await checkRegistration(postData);

            if (regStatus) {
                onSubmit({ registrantInfo: { ...postData, email_address: emailAddress, phone_number: phoneNumber, phone_type: phoneType, phone_number_optin: (checkboxValues.includes('optin') || false) }, registrationStatus: regStatus });
                setSubmitting(false);
            } else {
                alert('Sorry! Had trouble getting registration information.');
                onSubmit({ registrantInfo: { ...postData, email_address: emailAddress, phone_number: phoneNumber, phone_type: phoneType, phone_number_optin: (checkboxValues.includes('optin') || false) }, registrationStatus: null });
                setSubmitting(false);
            }
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <div className="relative">
            {submitting &&
                <div className="rounded-lg absolute w-full h-full justify-center items-center z-10 overflow-hidden">
                    <div className="rounded-lg bg-[#7395D4] absolute w-full h-full opacity-30"></div>
                    <Spinner />
                </div>
            }
            <form noValidate>
                <div className="flex flex-col sm:flex-row">
                    <div className="flex-1">
                        <InputText
                            label={formLabels[currentLanguage].first_name}
                            required
                            name="first_name"
                            errors={errors}
                            placeholder="Jane"
                            onChange={e => setFirstName(e.target.value)}
                            // onBlur={e => setFirstName(e.target.value)}
                            onFocus={() => {
                                setErrors(current => {
                                    const { first_name, ...newErrors } = current;
                                    return newErrors;
                                });
                            }}
                        />
                    </div>
                    <div className="flex-1">
                        <InputText
                            label={formLabels[currentLanguage].last_name}
                            required
                            name="last_name"
                            errors={errors}
                            placeholder="Doe"
                            onChange={e => setLastName(e.target.value)}
                            // onBlur={e => setLastName(e.target.value)}
                            onFocus={() => {
                                setErrors(current => {
                                    const { last_name, ...newErrors } = current;
                                    return newErrors;
                                });
                            }}
                        />
                    </div>
                </div>
                <div>
                    <InputText
                        label={formLabels[currentLanguage].date_of_birth}
                        required
                        name="date_of_birth"
                        errors={errors}
                        placeholder="mm/dd/yyyy"
                        maskType="dob"
                        onChange={e => setDateOfBirth(e.target.value)}
                        onFocus={() => {
                            setErrors(current => {
                                const { date_of_birth, ...newErrors } = current;
                                return newErrors;
                            });
                        }}
                    />
                </div>
                <div>
                    <InputText
                        label={formLabels[currentLanguage].email_address}
                        name="email_address"
                        errors={errors}
                        placeholder="jane@email.com"
                        onChange={e => setEmailAddress(e.target.value)}
                        onFocus={() => {
                            setErrors(current => {
                                const { email_address, ...newErrors } = current;
                                return newErrors;
                            });
                        }}
                    />
                </div>
                <div className="flex flex-col sm:flex-row">
                    <div className="flex-1">
                        <InputText
                            label={formLabels[currentLanguage].phone_number}
                            name="phone_number"
                            errors={errors}
                            placeholder="555-555-5555"
                            maskType={'phone'}
                            onChange={e => setPhoneNumber(e.target.value)}
                            onFocus={() => {
                                setErrors(current => {
                                    const { phone_number, ...newErrors } = current;
                                    return newErrors;
                                });
                            }}
                        />
                    </div>
                    <div className="flex-1">
                        <InputDropdown
                            name="phone_type"
                            label={formLabels[currentLanguage].phone_type}
                            errors={errors}
                            options={[
                                {
                                    label: formLabels[currentLanguage].mobile_phone,
                                    value: 'cell'
                                }, {
                                    label: formLabels[currentLanguage].home_phone,
                                    value: 'home'
                                }
                            ]}
                            placeholder={formLabels[currentLanguage].phone_type}
                            onValueChange={value => {
                                setErrors(current => {
                                    const { phone_type, ...newErrors } = current;
                                    return newErrors;
                                });
                                setPhoneType(value);
                            }}
                            selectedValue={phoneType}
                        />
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row">
                    <div className="flex-1 pt-3 pl-3">
                        <CheckboxList
                            value={checkboxValues}
                            size="small"
                            onChange={values => {
                                setCheckboxValues(values)
                            }}
                            width="100%"
                            items={[
                                { label: formLabels[currentLanguage].opt_in_text, value: 'optin' },
                            ]} />
                    </div>
                    <div className="flex-1">

                    </div>
                </div>
                <div className="px-2">
                    <Caption textAlign="right">
                        {formLabels[currentLanguage].required}
                    </Caption>
                    
                    <div className="flex flex-col-reverse sm:flex-row mt-8 items-end sm:items-center justify-end">
                        <div>
                            {/* <TextButton onClick={() => {}} title="I am ineligible to vote" /> */}
                        </div>
                        <div className="ml-4">
                            <PrimaryButton onClick={handleSubmit} title={buttons[currentLanguage].check_registration} />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

CheckRegistrationForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default CheckRegistrationForm;
