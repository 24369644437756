import React from "react";
import Heading1 from '../components/typography/Heading1';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import Wrapper from "../components/layout/Wrapper";
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import DateOfBirthConsentForm from "../forms/DateOfBirthConsentForm";

const content = {
  "en-US": {
      heading: "Please confirm your date of birth.",
  },
  "es-ES": {
      heading: "Confirme su fecha de nacimiento.",
  }
};

const DateOfBirthConsentPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  React.useEffect(() => {
    dispatch(changeStep([3, 6]));
  }, []);

  const handleDateOfBirthConsent = (values) => {
    dispatch(updateRegistrant({
      is_dob_consented: values.is_dob_consented, 
      consented_dob: (values.is_dob_consented ? values.dob_month+'/'+values.dob_day+'/'+values.dob_year : ''),
      date_of_birth_month: values.dob_month,
      date_of_birth_day: values.dob_day,
      date_of_birth_year: values.dob_year,
    }));

    navigate('/eligibility');
  };

  return (
    <Wrapper>
      <ContentLeft>
        <Heading1 mb={4}>
          {content[currentLanguage].heading}
        </Heading1>
      </ContentLeft>
      <ContentRight>
        <DateOfBirthConsentForm onSubmit={handleDateOfBirthConsent} />
      </ContentRight>
    </Wrapper>
  );
}

export default DateOfBirthConsentPage;