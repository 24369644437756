import React from 'react'
import { Path, G, Rect, Svg } from "react-native-svg"

const EditIcon = ({disabled, ...props}) => {
  return (
    <>
      <Svg className="w-[29px] h-[29px]" viewBox="0 0 29 29" {...props}>
        <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <G transform="translate(-627.000000, -336.000000)">
                <Rect fill="#F4F7F9" x="0" y="0" width="768" height="1024" />
                <G transform="translate(629.000000, 338.000000)" stroke="#B80F00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
                    <G>
                        <Path d="M10.5,2.99999626 L3,2.99999626 C1.34314501,2.99999626 -1.50990331e-13,4.34314127 -1.50990331e-13,5.99999626 L-1.50990331e-13,22.4999963 C-1.50990331e-13,24.1568964 1.34314501,25.4999963 3,25.4999963 L19.5,25.4999963 C21.1569001,25.4999963 22.5,24.1568964 22.5,22.4999963 L22.5,14.9999963 M20.3787001,0.878681254 C21.5502001,-0.292893751 23.4498001,-0.292893751 24.6213001,0.878681254 C25.7929501,2.05024126 25.7929501,3.94973627 24.6213001,5.12131127 L11.7426,18 L7.50000003,18 L7.50000003,13.7573963 L20.3787001,0.878681254 Z" />
                    </G>
                </G>
            </G>
        </G>
      </Svg>
    </>
  )
}

export default EditIcon;
