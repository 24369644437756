import React from 'react'

const Footer = () => {
  return (
    <footer>
        <div className="px-4 md:px-0 md:max-w-[80%] mx-auto flex flex-row justify-end py-6">
          <div>
            <div className='text-white text-right'>
              Copyright SeeBotRun 2022.  All rights reserved.
            </div>
            <div className='text-right'>
              <a href="/privacy-policy" className="underline inline text-white">Privacy Policy.</a>
              <a href="/terms-of-use" className="underline inline ml-1 text-white">Terms of Use.</a>
            </div>
          </div>
        </div>
    </footer>
  )
}

export default Footer
