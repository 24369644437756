import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import InputText from '../components/inputs/InputText';
import InputDropdown from '../components/inputs/InputDropdown';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { isEmpty, isValidZip } from '../helpers';
import { formLabels, formErrors, buttons } from '../content';
import Spinner from '../components/icons/Spinner';
import RadioButtonList from '../components/inputs/RadioButtonList';

const MailingAddressForm = ({ onSubmit, ...props }) => {
    const registrant = useSelector((state) => state.registrant);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const [mailingAddressType, setMailingAddressType] = React.useState('us');
    const [formData, setData] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        /*setData({
            mailing_address_1: '',
            mailing_address_2_type: '',
            mailing_address_2_number: '',
            mailing_city: '',
            mailing_state: '',
            mailing_region: '',
            mailing_zipcode: '',
            mailing_country: '',
        });*/
        setErrors({});
    }, [mailingAddressType]);

    React.useEffect(() => {
        const {
            mailing_address_type,
            mailing_address_1,
            mailing_address_2_type,
            mailing_address_2_number,
            mailing_city,
            mailing_state,
            mailing_region,
            mailing_zipcode,
            mailing_country,
        } = registrant;

        if (!isEmpty(mailing_address_type)) {
            setMailingAddressType(mailing_address_type);
        }

        setData({
            mailing_address_1,
            mailing_address_2_type,
            mailing_address_2_number,
            mailing_city,
            mailing_state,
            mailing_region,
            mailing_zipcode,
            mailing_country,
        });
    }, [registrant]);

    React.useEffect(() => {
        const {
            mailing_address_type,
        } = registrant;

        if (!isEmpty(mailing_address_type)) {
            setMailingAddressType(mailing_address_type);
        }
    }, []);

    const updateMailingType = () => {
        setData({
            mailing_address_1: '',
            mailing_address_2_type: '',
            mailing_address_2_number: '',
            mailing_city: '',
            mailing_state: '',
            mailing_region: '',
            mailing_zipcode: '',
            mailing_country: '',
        });

        setErrors({});
    };

    const isValid = () => {
        let checkErrors = {};

        const {
            mailing_address_1,
            mailing_address_2_type,
            mailing_address_2_number,
            mailing_city,
            mailing_state,
            mailing_region,
            mailing_zipcode,
            mailing_country,
        } = formData;

        if (isEmpty(mailing_address_1)) {
            checkErrors = {
                ...checkErrors,
                mailing_address_1: `${mailingAddressType === 'po' ? formLabels[currentLanguage].pobox_mailing_address_1 : formLabels[currentLanguage].mailing_address_1} ${formErrors[currentLanguage].is_required}`,
            };
        } else if (mailing_address_1.length > 255) {
            checkErrors = {
                ...checkErrors,
                mailing_address_1: `${mailingAddressType === 'po' ? formLabels[currentLanguage].pobox_mailing_address_1 : formLabels[currentLanguage].mailing_address_1} ${formErrors[currentLanguage].is_required}`,
            };
        }

        if (mailingAddressType === 'us') {
            if (isEmpty(mailing_address_2_number) && !isEmpty(mailing_address_2_type)) {
                checkErrors = {
                    ...checkErrors,
                    mailing_address_2_number: formErrors[currentLanguage].unit_number_required,
                };
            } else if (!isEmpty(mailing_address_2_number) && mailing_address_2_number.length > 15) {
                checkErrors = {
                    ...checkErrors,
                    mailing_address_2_number: formErrors[currentLanguage].unit_number_required,
                };
            }
        } else if (mailingAddressType === 'foreign') {
            if (!isEmpty(mailing_address_2_number) && mailing_address_2_number.length > 50) {
                checkErrors = {
                    ...checkErrors,
                    mailing_address_2_number: formErrors[currentLanguage].valid_address_2,
                };
            }
        }

        if (isEmpty(mailing_city)) {
            checkErrors = {
                ...checkErrors,
                mailing_city: formErrors[currentLanguage].city_required,
            };
        } else if (mailing_city.length > 50) {
            checkErrors = {
                ...checkErrors,
                mailing_city: formErrors[currentLanguage].city_required,
            };
        }

        if (isEmpty(mailing_state) && (mailingAddressType === 'us' || mailingAddressType === 'po')) {
            checkErrors = {
                ...checkErrors,
                mailing_state: formErrors[currentLanguage].state_required,
            };
        } else if (mailing_state.length > 2 && (mailingAddressType === 'us' || mailingAddressType === 'po')) {
            checkErrors = {
                ...checkErrors,
                mailing_state: formErrors[currentLanguage].state_required,
            };
        }

        if (isEmpty(mailing_region) && mailingAddressType === 'foreign') {
            checkErrors = {
                ...checkErrors,
                mailing_region: formErrors[currentLanguage].state_required,
            };
        } else if (mailing_region.length > 50 && mailingAddressType === 'foreign') {
            checkErrors = {
                ...checkErrors,
                mailing_region: formErrors[currentLanguage].state_required,
            };
        }

        if (mailingAddressType === 'us' || mailingAddressType === 'po') {
            if (isEmpty(mailing_zipcode)) {
                checkErrors = {
                    ...checkErrors,
                    mailing_zipcode: formErrors[currentLanguage].zipcode_required,
                };
            } else if (!isValidZip(mailing_zipcode)) {
                checkErrors = {
                    ...checkErrors,
                    mailing_zipcode: formErrors[currentLanguage].zipcode_required,
                };
            } else if (mailing_zipcode.length > 10) {
                checkErrors = {
                    ...checkErrors,
                    mailing_zipcode: formErrors[currentLanguage].zipcode_required,
                };
            }
        } else {
            if (isEmpty(mailing_zipcode)) {
                checkErrors = {
                    ...checkErrors,
                    mailing_zipcode: formErrors[currentLanguage].valid_postal_code,
                };
            } else if (mailing_zipcode.length > 20) {
                checkErrors = {
                    ...checkErrors,
                    mailing_zipcode: formErrors[currentLanguage].valid_postal_code,
                };
            }
        }

        if (isEmpty(mailing_country) && mailingAddressType === 'foreign') {
            checkErrors = {
                ...checkErrors,
                mailing_country: formErrors[currentLanguage].country_required,
            };
        } else if (mailing_country.length > 50 && mailingAddressType === 'foreign') {
            checkErrors = {
                ...checkErrors,
                mailing_country: formErrors[currentLanguage].country_required,
            };
        }

        setErrors(checkErrors);

        if (!!Object.keys(checkErrors).length) {
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = async () => {
        if (!isValid()) {
            return;
        }

        try {
            setSubmitting(true);

            onSubmit({
                has_mailing_address: true,
                mailing_address_type: mailingAddressType,
                ...formData,
            });

            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <div className="relative">
            {submitting &&
                <div className="absolute z-10 items-center justify-center w-full h-full overflow-hidden rounded-lg">
                    <div className="rounded-lg bg-[#7395D4] absolute w-full h-full opacity-30"></div>
                    <Spinner />
                </div>
            }
            <form noValidate>
                <RadioButtonList value={mailingAddressType} onChange={value => { updateMailingType(); setMailingAddressType(value); }} items={[
                    { label: formLabels[currentLanguage].us_mailing_address, value: 'us' },
                    { label: formLabels[currentLanguage].foreign_mailing_address, value: 'foreign' },
                    { label: formLabels[currentLanguage].po_box, value: 'po' }
                ]} />

                <div>
                    <InputText
                        label={mailingAddressType === 'us' ? formLabels[currentLanguage].mailing_address_1 : mailingAddressType === 'foreign' ? formLabels[currentLanguage].foreign_mailing_address_1 : formLabels[currentLanguage].pobox_mailing_address_1}
                        required
                        name="mailing_address_1"
                        errors={errors}
                        onChange={e => {
                            setData({ ...formData, mailing_address_1: e.target.value });
                        }}
                        onBlur={() => {
                            setErrors(current => {
                                const { mailing_address_1, ...newErrors } = current;
                                return newErrors;
                            });
                        }}
                        // onSubmitEditing={handleSubmit}
                        value={formData.mailing_address_1}
                    />
                </div>
                <div className="flex flex-col sm:flex-row">
                    {mailingAddressType === 'us' &&
                        <div className="flex-1">
                            <InputDropdown
                                name="mailing_address_2_type"
                                label={formLabels[currentLanguage].apt_type}
                                errors={errors}
                                placeholder={formLabels[currentLanguage].select_option}
                                options={[
                                    { value: "APT", label: "APT" },
                                    { value: "UNIT", label: "UNIT" },
                                    { value: "RM", label: "RM" },
                                    { value: "SUITE", label: "SUITE" },
                                    { value: "BSMT", label: "BSMT" },
                                    { value: "BLDG", label: "BLDG" },
                                    { value: "DEPT", label: "DEPT" },
                                    { value: "FL", label: "FL" },
                                    { value: "FRNT", label: "FRNT" },
                                    { value: "HNGR", label: "HNGR" },
                                    { value: "KEY", label: "KEY" },
                                    { value: "LBBY", label: "LBBY" },
                                    { value: "LOT", label: "LOT" },
                                    { value: "LOWR", label: "LOWR" },
                                    { value: "OFC", label: "OFC" },
                                    { value: "PH", label: "PH" },
                                    { value: "PIER", label: "PIER" },
                                    { value: "SIDE", label: "SIDE" },
                                    { value: "REAR", label: "REAR" },
                                    { value: "SLIP", label: "SLIP" },
                                    { value: "SPC", label: "SPC" },
                                    { value: "STOP", label: "STOP" },
                                    { value: "UPPR", label: "UPPR" },
                                    { value: "TRLR", label: "TRLR" },
                                    { value: "BOX", label: "BOX" },
                                ]}
                                onValueChange={value => {
                                    setErrors(current => {
                                        const { mailing_address_2_type, ...newErrors } = current;
                                        return newErrors;
                                    });
                                    setData({ ...formData, mailing_address_2_type: value });
                                }}
                                selectedValue={formData.mailing_address_2_type}
                            // onSubmitEditing={handleSubmit}
                            />
                        </div>
                    }
                    {mailingAddressType !== 'po' &&
                        <div className="flex-1">
                            <InputText
                                label={mailingAddressType === 'foreign' ? formLabels[currentLanguage].mailing_address_2 : formLabels[currentLanguage].apt_number}
                                name="mailing_address_2_number"
                                errors={errors}
                                onChange={e => {
                                    setData({ ...formData, mailing_address_2_number: e.target.value });
                                }}
                                onBlur={() => {
                                    setErrors(current => {
                                        const { mailing_address_2_number, ...newErrors } = current;
                                        return newErrors;
                                    });
                                }}
                                // onSubmitEditing={handleSubmit}
                                value={formData.mailing_address_2_number}
                            />
                        </div>
                    }
                </div>
                <div className="flex flex-col sm:flex-row">
                    <div className="flex-1">
                        <InputText
                            label={formLabels[currentLanguage].city}
                            name="mailing_city"
                            errors={errors}
                            required
                            onChange={e => {
                                setData({ ...formData, mailing_city: e.target.value });
                            }}
                            onBlur={() => {
                                setErrors(current => {
                                    const { mailing_city, ...newErrors } = current;
                                    return newErrors;
                                });
                            }}
                            // onSubmitEditing={handleSubmit}
                            value={formData.mailing_city}
                        />
                    </div>
                    <div className="flex-1">
                        {mailingAddressType !== 'foreign' ? (
                            <InputDropdown
                                name="mailing_state"
                                label={formLabels[currentLanguage].state}
                                required
                                errors={errors}
                                placeholder={formLabels[currentLanguage].select_option}
                                options={[
                                    {
                                        label: "Alabama",
                                        value: "AL"
                                    },
                                    {
                                        label: "Alaska",
                                        value: "AK"
                                    },
                                    {
                                        label: "American Samoa",
                                        value: "AS"
                                    },
                                    {
                                        label: "Arizona",
                                        value: "AZ"
                                    },
                                    {
                                        label: "Arkansas",
                                        value: "AR"
                                    },
                                    {
                                        label: "California",
                                        value: "CA"
                                    },
                                    {
                                        label: "Colorado",
                                        value: "CO"
                                    },
                                    {
                                        label: "Connecticut",
                                        value: "CT"
                                    },
                                    {
                                        label: "Delaware",
                                        value: "DE"
                                    },
                                    {
                                        label: "District Of Columbia",
                                        value: "DC"
                                    },
                                    {
                                        label: "Federated States Of Micronesia",
                                        value: "FM"
                                    },
                                    {
                                        label: "Florida",
                                        value: "FL"
                                    },
                                    {
                                        label: "Georgia",
                                        value: "GA"
                                    },
                                    {
                                        label: "Guam",
                                        value: "GU"
                                    },
                                    {
                                        label: "Hawaii",
                                        value: "HI"
                                    },
                                    {
                                        label: "Idaho",
                                        value: "ID"
                                    },
                                    {
                                        label: "Illinois",
                                        value: "IL"
                                    },
                                    {
                                        label: "Indiana",
                                        value: "IN"
                                    },
                                    {
                                        label: "Iowa",
                                        value: "IA"
                                    },
                                    {
                                        label: "Kansas",
                                        value: "KS"
                                    },
                                    {
                                        label: "Kentucky",
                                        value: "KY"
                                    },
                                    {
                                        label: "Louisiana",
                                        value: "LA"
                                    },
                                    {
                                        label: "Maine",
                                        value: "ME"
                                    },
                                    {
                                        label: "Marshall Islands",
                                        value: "MH"
                                    },
                                    {
                                        label: "Maryland",
                                        value: "MD"
                                    },
                                    {
                                        label: "Massachusetts",
                                        value: "MA"
                                    },
                                    {
                                        label: "Michigan",
                                        value: "MI"
                                    },
                                    {
                                        label: "Minnesota",
                                        value: "MN"
                                    },
                                    {
                                        label: "Mississippi",
                                        value: "MS"
                                    },
                                    {
                                        label: "Missouri",
                                        value: "MO"
                                    },
                                    {
                                        label: "Montana",
                                        value: "MT"
                                    },
                                    {
                                        label: "Nebraska",
                                        value: "NE"
                                    },
                                    {
                                        label: "Nevada",
                                        value: "NV"
                                    },
                                    {
                                        label: "New Hampshire",
                                        value: "NH"
                                    },
                                    {
                                        label: "New Jersey",
                                        value: "NJ"
                                    },
                                    {
                                        label: "New Mexico",
                                        value: "NM"
                                    },
                                    {
                                        label: "New York",
                                        value: "NY"
                                    },
                                    {
                                        label: "North Carolina",
                                        value: "NC"
                                    },
                                    {
                                        label: "North Dakota",
                                        value: "ND"
                                    },
                                    {
                                        label: "Northern Mariana Islands",
                                        value: "MP"
                                    },
                                    {
                                        label: "Ohio",
                                        value: "OH"
                                    },
                                    {
                                        label: "Oklahoma",
                                        value: "OK"
                                    },
                                    {
                                        label: "Oregon",
                                        value: "OR"
                                    },
                                    {
                                        label: "Palau",
                                        value: "PW"
                                    },
                                    {
                                        label: "Pennsylvania",
                                        value: "PA"
                                    },
                                    {
                                        label: "Puerto Rico",
                                        value: "PR"
                                    },
                                    {
                                        label: "Rhode Island",
                                        value: "RI"
                                    },
                                    {
                                        label: "South Carolina",
                                        value: "SC"
                                    },
                                    {
                                        label: "South Dakota",
                                        value: "SD"
                                    },
                                    {
                                        label: "Tennessee",
                                        value: "TN"
                                    },
                                    {
                                        label: "Texas",
                                        value: "TX"
                                    },
                                    {
                                        label: "Utah",
                                        value: "UT"
                                    },
                                    {
                                        label: "Vermont",
                                        value: "VT"
                                    },
                                    {
                                        label: "Virgin Islands",
                                        value: "VI"
                                    },
                                    {
                                        label: "Virginia",
                                        value: "VA"
                                    },
                                    {
                                        label: "Washington",
                                        value: "WA"
                                    },
                                    {
                                        label: "West Virginia",
                                        value: "WV"
                                    },
                                    {
                                        label: "Wisconsin",
                                        value: "WI"
                                    },
                                    {
                                        label: "Wyoming",
                                        value: "WY"
                                    }
                                ]}
                                onValueChange={value => {
                                    setErrors(current => {
                                        const { mailing_state, ...newErrors } = current;
                                        return newErrors;
                                    });
                                    setData({ ...formData, mailing_state: value });
                                }}
                                selectedValue={formData.mailing_state}
                            // onSubmitEditing={handleSubmit}
                            />
                        ) : (
                            <InputText
                                label={formLabels[currentLanguage].mailing_region}
                                name="mailing_region"
                                errors={errors}
                                required
                                onChange={e => {
                                    setData({ ...formData, mailing_region: e.target.value });
                                }}
                                onBlur={() => {
                                    setErrors(current => {
                                        const { mailing_region, ...newErrors } = current;
                                        return newErrors;
                                    });
                                }}
                                // onSubmitEditing={handleSubmit}
                                value={formData.mailing_region}
                            />
                        )}
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="flex-1">
                        <InputText
                            label={mailingAddressType === 'foreign' ? formLabels[currentLanguage].mailing_zipcode : formLabels[currentLanguage].zipcode}
                            name="mailing_zipcode"
                            errors={errors}
                            required
                            onChange={e => {
                                setData({ ...formData, mailing_zipcode: e.target.value });
                            }}
                            onBlur={() => {
                                setErrors(current => {
                                    const { mailing_zipcode, ...newErrors } = current;
                                    return newErrors;
                                });
                            }}
                            // onSubmitEditing={handleSubmit}
                            value={formData.mailing_zipcode}
                            maskType={mailingAddressType === 'foreign' ? 'postalcode' : 'zipcode'}
                        />
                    </div>
                    <div className="flex-1">
                        {mailingAddressType === 'foreign' &&
                            <InputText
                                label={formLabels[currentLanguage].mailing_country}
                                name="mailing_country"
                                errors={errors}
                                required
                                onChange={e => {
                                    setData({ ...formData, mailing_country: e.target.value });
                                }}
                                onBlur={() => {
                                    setErrors(current => {
                                        const { mailing_country, ...newErrors } = current;
                                        return newErrors;
                                    });
                                }}
                                // onSubmitEditing={handleSubmit}
                                value={formData.mailing_country}
                            />
                        }
                    </div>
                </div>
                <div className="px-1 mt-8">
                    <div className="flex flex-row items-center justify-end mt-3">
                        <div className="ml-4">
                            <PrimaryButton onClick={handleSubmit} title={buttons[currentLanguage].next_step} />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

MailingAddressForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default MailingAddressForm;
