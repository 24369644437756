import React from 'react';
import TextButton from '../components/buttons/TextButton';
import PrimaryButton from '../components/buttons/PrimaryButton';
import InputError from '../components/inputs/InputError';
import isNumeric from 'validator/es/lib/isNumeric';

const LastFourForm = ({ updateMode, onChangeCode, onSubmit, onCancel, hasError, ...props }) => {
    const [code, setCode] = React.useState(['', '', '', '']);
    const [errors, setErrors] = React.useState({});
    const itemsRef = React.useRef([]);

    React.useEffect(() => {
        if (!updateMode) {
            itemsRef.current[0].focus();
        }
    }, [itemsRef]);

    const writeCode = (value, index) => {
        const newCode = code.map((charValue, charIndex) => {
            return charIndex === index ? value : charValue;
        });
        setCode(newCode);
        onChangeCode(newCode.join(''));
    };

    const lastFourValid = () => {
        if (code.join('').length != code.length) {
            setErrors({
                ...errors,
                code: 'Code is required'
            });
            return false;
        }
        if (!isNumeric(code[0]) || !isNumeric(code[1]) || !isNumeric(code[2]) || !isNumeric(code[3])) {
            setErrors({
                ...errors,
                code: 'Code must be numbers'
            });
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        if (!lastFourValid()) {
            return;
        }
        onSubmit(code);
    };

    const inputStyleProps = {
        textAlign: 'center',
        fontSize: 'xl',
        bg: 'white',
        borderRadius: 'md',
        borderColor: 'primary.700'
    };

    return (
        <div className="p-12">
            <div className="flex items-center justify-center">
                <div className="flex flex-row items-center">
                    <div className="px-2 text-[#11156f] font-heading font-black text-2xl">X</div>
                    <div className="px-2 text-[#11156f] font-heading font-black text-2xl">X</div>
                    <div className="px-2 text-[#11156f] font-heading font-black text-2xl">X</div>
                    <div className="px-2 text-[#11156f] font-heading font-black text-2xl">-</div>
                    <div className="px-2 text-[#11156f] font-heading font-black text-2xl">X</div>
                    <div className="px-2 text-[#11156f] font-heading font-black text-2xl">X</div>
                    <div className="px-2 text-[#11156f] font-heading font-black text-2xl">-</div>
                    {code.map((value, index) => {
                        return (
                            <div key={`lastFourCode${index}`} className={`mx-1`} isRequired isInvalid={'code' in errors}>
                                <input
                                    className={`w-10 h-12 border-2 ${'code' in errors ? 'border-[#B80F00]' : 'border-[#11156f]'} text-[#11156f] rounded-lg font-heading font-black text-2xl text-center`}
                                    value={value}
                                    maxLength={1}
                                    onFocus={() => {
                                        setErrors({})
                                        writeCode('', index)
                                    }}
                                    ref={el => itemsRef.current[index] = el}
                                    onChange={(e) => {
                                        if (e.currentTarget.value && index < code.length - 1) {
                                            itemsRef.current[index + 1].focus();
                                        }
                                        writeCode(e.currentTarget.value, index)
                                    }}
                                    onBlur={({ nativeEvent }) => nativeEvent.key === 'Backspace' && index > 0 ? itemsRef.current[index - 1].focus() : null}
                                    onSubmitEditing={onSubmit}
                                    keyboardType='number-pad'
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
            
            <div className="flex items-center justify-center">{'code' && errors && <InputError>{errors['code']}</InputError>}</div>
            
            <div className="flex flex-row justify-end mt-10">
                <div>
                    <TextButton onClick={onCancel} title="Cancel" />
                </div>
                <div className='ml-4'>
                    <PrimaryButton
                        title={updateMode ? "Update Information" : "Next Question"}
                        onClick={() => handleSubmit()}
                    />
                </div>
            </div>
        </div>
    )
}

export default LastFourForm;
