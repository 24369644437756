import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from "../components/layout/Wrapper";
import Paragraph from '../components/typography/Paragraph';
import Heading1 from '../components/typography/Heading1';
import PrimaryButton from '../components/buttons/PrimaryButton';
import RadioButtonList from '../components/inputs/RadioButtonList';
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { useNavigate } from "react-router-dom";
import { formErrors, buttons } from '../content';

export const content = {
    "en-US": {
        heading: "Have you updated your address with the Department of Motor Vehicles (DMV)?",
        paragraph: <>In order to change your address on your voter registration, we'll need to update your address with the <a href="https://trust.dot.state.wi.us/dmvac/dmvacservlet?whoami=search" target="_blank">DMV</a>.</>,
        updated: "Yes, I have updated my address with the DMV.",
        not_updated: "No, I have not updated my address with the DMV.",
        unsure: "I don't know if I have updated my address.",
    },
    "es-ES": {
        heading: "¿Ha actualizado su dirección en el Departamento de Vehículos Motorizados (DMV en inglés)?",
        paragraph: <>Para cambiar su dirección en su registro de votante, debe actualizar su dirección en el <a href="https://trust.dot.state.wi.us/dmvac/dmvacservlet?whoami=search" target="_blank">DMV</a>.</>,
        updated: "Sí, he actualizado mi dirección en el DMV.",
        not_updated: "No, no he actualizado  mi dirección en el DMV.",
        unsure: "No sé si he actualizado mi dirección.",
    }
};

function UpdateAddressPage({ navigation }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const registrant = useSelector((state) => state.registrant);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const [value, setValue] = React.useState([]);
    const [hasError, setError] = React.useState(false);

    const onChange = (value) => {
        setError(false);
        setValue(value);
    };

    const nextStep = () => {
        if (!value) {
            setError(true);
            return;
        }

        dispatch(updateRegistrant({
            dmv_address_updated: value,
        }));

        if (value === 'updated') {
            navigate('/update-address-form');
        }
        else if (value === 'not_updated') {
            window.open('https://trust.dot.state.wi.us/dmvac/dmvacservlet?whoami=search', '_blank');
            navigate('/update-address-form');
        }
        else if (value === 'unsure') {
            window.open('https://trust.dot.state.wi.us/dmvac/dmvacservlet?whoami=search', '_blank');
            navigate('/update-address-form');
        }
    };

    const populateCheckboxes = () => {
        const {
            dmv_address_updated,
        } = registrant;

        setValue(dmv_address_updated);
    };

    React.useEffect(() => {
        populateCheckboxes();
    }, [registrant]);


    React.useEffect(() => {
        populateCheckboxes();
        dispatch(changeStep([2, 6]));
    }, []);

    return (
        <Wrapper>
            <ContentLeft>
                <Heading1 mb={4}>{content[currentLanguage].heading}</Heading1>
                <Paragraph mb={4}>{content[currentLanguage].paragraph}</Paragraph>
            </ContentLeft>
            <ContentRight>
                <div className="px-2 sm:px-2 lg:px-0x">
                    <div className="flex flex-col p-2 w-full">
                        <RadioButtonList value={value} onChange={onChange} width="100%" items={[
                            { label: content[currentLanguage].updated, value: 'updated' },
                            { label: content[currentLanguage].not_updated, value: 'not_updated' },
                            { label: content[currentLanguage].unsure, value: 'unsure' },
                        ]} />
                        {/* <FormControl.ErrorMessage _text={{ fontWeight: 'bold' }}>
                            {formErrors[currentLanguage].choose_option}
                        </FormControl.ErrorMessage> */}
                    </div>
                    <div className="w-full mt-4 flex justify-end">
                        <PrimaryButton onClick={nextStep} title={buttons[currentLanguage].next_question} />
                    </div>
                </div>
            </ContentRight>
        </Wrapper>
    );
}

export default UpdateAddressPage;
