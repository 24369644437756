import React from "react";
import PropTypes from "prop-types";

const FormWrapper = ({ children, ...props }) => {
  return (
    <div className="flex flex-col w-full py-6 mb-6 bg-white rounded-lg" {...props}>
      {children}
    </div>
  );
};

FormWrapper.prototype = {
  children: PropTypes.node.isRequired,
};

export default FormWrapper;
