import React from 'react';
import InputLabel from './InputLabel';
import InputError from './InputError';

const inputStyleProps = {
    fontSize: 'md',
    borderWidth: '2px',
    borderColor: 'primary.700',
    borderRadius: '20px',
    bg: 'white',
    p: 6
};


const TextArea = ({ label, errors, name, required, mask, ...props }) => {
    return (
        <div className="flex flex-col p-2 w-full">
            {label &&
                <InputLabel>
                    {label}
                </InputLabel>
            }
            <textarea className={` resize-none mt-2 border-2 ${name in errors ? 'input--error' : 'border-[#13187C]'} rounded-lg h-[150px] p-2`} {...props} />
            {name in errors && <InputError>{errors[name]}</InputError>}
        </div>
    )
}

export default TextArea;
