import base64 from 'base-64';
import forge from 'node-forge'
import { v4 as uuidv4 } from 'uuid';

export const getPublicKey = () => {
    return fetch(`${process.env.REACT_APP_API_BASE}voter/registration/key?canvasser_id=${process.env.REACT_APP_CANVASSER_ID}`).then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }

        return response.json();
    }).catch((error) => {
        console.log(error);
    });
}

export const getRegistrationFields = () => {
    return fetch(`${process.env.REACT_APP_API_BASE}voter/registration?canvasser_id=${process.env.REACT_APP_CANVASSER_ID}`).then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }

        return response.json()
    }).catch((error) => {
        console.log(error);
    });
}

export const checkRegistration = (registrant) => {
    return fetch(`${process.env.REACT_APP_API_BASE}voter/lookup`, { method: 'POST', headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}, body: JSON.stringify(registrant)})
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json()
        })
        .catch((error) => {
            console.log(error);
        });
}

export const createFormPayload = (formData, registrationType, language, pubkey) => {
    let registrationFormData = JSON.parse(JSON.stringify(formData));

    delete registrationFormData.signature_data;
    delete registrationFormData.assistant_signature_data;
    delete registrationFormData.proof_of_residence;

    let encrypted_result = {
        key: '',
        payload: '',
    };
    let result = {
        type: registrationType,
        version: '0.0.1',
        language: language,
        latitude: '',
        longitude: '',
        platform: 'website',
        date_collected: new Date().toISOString(),
        registration_form: registrationFormData,
    };

    // AES Encryption Setup
    // Step 1: Get a key/IV, use the AES-CBC method, and encrypt the payload
    // Step 2: Get the base64 encoded payload out
    // Step 3: Generate the HMAC for integrity?  Build using the IV and payload output
    let key = forge.random.getBytesSync(32);
    let iv = forge.random.getBytesSync(16);
    let hmac_key = forge.random.getBytesSync(32);

    let cipher = forge.cipher.createCipher('AES-CBC', key);
    //cipher.mode.pad = false;
    //cipher.mode.unpad = false;
    cipher.start({iv: iv});
    cipher.update(forge.util.createBuffer(JSON.stringify(result)));
    cipher.finish();
    let cipher_output = cipher.output.getBytes();

    // Generate HMAC
    let iv_cipher = iv + cipher_output;
    let hmac = forge.hmac.create();
    hmac.start('sha256', hmac_key);
    hmac.update(iv_cipher);

    //console.log('Key: '+String.fromCharCode.apply(String, key));
    //console.log('HMAC: '+String.fromCharCode.apply(String, hmac_key));
    //console.log('IV: '+String.fromCharCode.apply(String, iv));

    // RSA Encryption
    // Step 1: Build the public key
    // Step 2: Build the AES key string from the IV and HMAC
    // Step 3: Encrypt using public key encryption
    let pem = '-----BEGIN PUBLIC KEY-----\n' + pubkey + '\n-----END PUBLIC KEY-----'
    let publicKey = forge.pki.publicKeyFromPem(pem);
    let buffer = forge.util.createBuffer(forge.util.encode64(key) + ':' + forge.util.encode64(hmac_key) + ':' + forge.util.encode64(process.env.REACT_APP_CANVASSER_ID) + ':' + forge.util.encode64('form'), 'utf8');
    let bytes = buffer.getBytes();
    let encrypted = publicKey.encrypt(bytes, 'RSA-OAEP');
    let digest = hmac.digest().bytes();

    // Complete - Set the payload/key
    let payload = forge.util.encode64(iv) + ':' + forge.util.encode64(digest) + ':' + forge.util.encode64(cipher_output);

    encrypted_result.payload = payload;
    encrypted_result.key = forge.util.encode64(encrypted);

    return encrypted_result;
}

export const createImagePayload = (imageData, imageType, pubkey) => {
    let encrypted_result = {
        key: '',
        payload: '',
    };

    // AES Encryption Setup
    // Step 1: Get a key/IV, use the AES-CBC method, and encrypt the payload
    // Step 2: Get the base64 encoded payload out
    // Step 3: Generate the HMAC for integrity?  Build using the IV and payload output
    let key = forge.random.getBytesSync(32);
    let iv = forge.random.getBytesSync(16);
    let hmac_key = forge.random.getBytesSync(32);

    let cipher = forge.cipher.createCipher('AES-CBC', key);
    //cipher.mode.pad = false;
    //cipher.mode.unpad = false;
    cipher.start({iv: iv});
    cipher.update(forge.util.createBuffer(imageData));
    cipher.finish();
    let cipher_output = cipher.output.getBytes();

    // Generate HMAC
    let iv_cipher = iv + cipher_output;
    let hmac = forge.hmac.create();
    hmac.start('sha256', hmac_key);
    hmac.update(iv_cipher);

    //console.log('Key: '+String.fromCharCode.apply(String, key));
    //console.log('HMAC: '+String.fromCharCode.apply(String, hmac_key));
    //console.log('IV: '+String.fromCharCode.apply(String, iv));

    // RSA Encryption
    // Step 1: Build the public key
    // Step 2: Build the AES key string from the IV and HMAC
    // Step 3: Encrypt using public key encryption
    let pem = '-----BEGIN PUBLIC KEY-----\n' + pubkey + '\n-----END PUBLIC KEY-----'
    let publicKey = forge.pki.publicKeyFromPem(pem);
    let buffer = forge.util.createBuffer(forge.util.encode64(key) + ':' + forge.util.encode64(hmac_key) + ':' + forge.util.encode64(process.env.REACT_APP_CANVASSER_ID) + ':' + forge.util.encode64(imageType), 'utf8');
    let bytes = buffer.getBytes();
    let encrypted = publicKey.encrypt(bytes, 'RSA-OAEP');
    let digest = hmac.digest().bytes();

    // Complete - Set the payload/key
    let payload = forge.util.encode64(iv) + ':' + forge.util.encode64(digest) + ':' + forge.util.encode64(cipher_output);

    encrypted_result.payload = payload;
    encrypted_result.key = forge.util.encode64(encrypted);

    return encrypted_result;
}

export const sendSurveyData = (responseID, shiftID, type, key, payload) => {
    let post_body = {
        canvasser_id: process.env.REACT_APP_CANVASSER_ID,
        response_id: responseID,
        shift_id: shiftID,
        type: type,
        key: key,
        payload: payload,
    };

    return fetch(`${process.env.REACT_APP_API_BASE}voter/registration`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(post_body),
    }).then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }

        return response.text();
    }).then((text) => {
        //console.log(text);
    }).catch(function(error) {
        console.log(error);
    });
}

export const checkReturnUser = (registrant) => {
    return fetch(`${process.env.REACT_APP_API_BASE}voter/registration/return`, { method: 'POST', headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}, body: JSON.stringify(registrant)})
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json()
        })
        .catch((error) => {
            console.log(error);
        });
}

export const generateRegistrationID = () => {
    return uuidv4();
}

export const encodeKey = (rsaPubKey) => {
    return base64.encode(rsaPubKey);
}

export const checkAddress = (address1, address2Number, address2Type, city, state, zipcode) => {
    return fetch(`${process.env.REACT_APP_API_BASE}voter/address?address_1=${address1}&address_2_number=${address2Number}&address_2_type=${address2Type}&city=${city}&state=${state}&zipcode=${zipcode}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    }).then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json()
    }).catch((error) => {
        console.log('error', error);
    });
}
