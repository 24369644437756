import React from 'react';
import PropTypes from 'prop-types';

function TextButton({ onClick, title, children, ...props }) {
  return (
    <button
        onClick={onClick}
        className='text-sm mt-4 mb-4 uppercase font-black text-[#7395D4] tracking-wide p-0'
        {...props}
        >
        {title ? title : children}
    </button>
  );
}

TextButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    children: PropTypes.node,
};

export default TextButton;
