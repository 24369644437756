import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import InputText from '../components/inputs/InputText';
import InputDropdown from '../components/inputs/InputDropdown';
import PrimaryButton from '../components/buttons/PrimaryButton';
import TextButton from '../components/buttons/TextButton';
import Caption from '../components/typography/Caption';
import CheckboxList from '../components/inputs/CheckboxList';
import isEmail from 'validator/lib/isEmail';
import { isEmpty, isValidPhoneNumber } from '../helpers';
import { generateRegistrationID, createFormPayload, sendSurveyData, getPublicKey } from '../services/api/voter';
import { makeDate, makePhoneNumber } from '../helpers/index';
import { formLabels, formErrors, buttons } from '../content';
import Spinner from '../components/icons/Spinner';

const OptInForm = ({ onSubmit, registrationType }) => {
    const navigate = useNavigate();
    const registrant = useSelector((state) => state.registrant);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const [emailAddress, setEmailAddress] = React.useState(registrant.email_address);
    const [emailAddressOptin, setEmailAddressOptin] = React.useState(registrant.email_address_optin);
    const [phoneType, setPhoneType] = React.useState(registrant.phone_type);
    const [phoneNumber, setPhoneNumber] = React.useState((registrant.phone_number_area ? registrant.phone_number_area+'-'+registrant.phone_number.substring(0, 3)+'-'+registrant.phone_number.substring(3) : ''));
    const [checkboxValues, setCheckboxValues] = React.useState([registrant.phone_number_optin ? 'optin' : null]);
    const [errors, setErrors] = React.useState({});
    const [submitting, setSubmitting] = React.useState(false);

    const debouncedSetEmailAddress = useDebouncedCallback(
        (value) => setEmailAddress(value),
        500
    );

    const debouncedSetPhoneType = useDebouncedCallback(
        (value) => setPhoneType(value),
        500
    );

    const debouncedSetPhoneNumber = useDebouncedCallback(
        (value) => setPhoneNumber(value),
        500
    );

    const isValid = () => {
        let checkErrors = {};

        if (!isEmpty(emailAddress) && !isEmail(emailAddress)) {
            checkErrors = {
                ...checkErrors,
                email_address: formErrors[currentLanguage].required_email,
            };
        } else if (emailAddress.length > 100) {
            checkErrors = {
                ...checkErrors,
                email_address: formErrors[currentLanguage].valid_email,
            };
        } else if (isEmpty(emailAddress) && isEmpty(phoneNumber.replace('-', '').replace('_', ''))) {
            checkErrors = {
                ...checkErrors,
                email_address: formErrors[currentLanguage].phone_number_or_email,
            };
        }

        if (!isEmpty(phoneNumber.replace('-', '').replace('_', '')) && !isValidPhoneNumber(phoneNumber)) {
            checkErrors = {
                ...checkErrors,
                phone_number: formErrors[currentLanguage].valid_phone_number,
            };
        } else if (isEmpty(emailAddress) && isEmpty(phoneNumber.replace('-', '').replace('_', ''))) {
            checkErrors = {
                ...checkErrors,
                phone_number: formErrors[currentLanguage].phone_number_or_email,
            };
        }

        setErrors(checkErrors);

        if (!!Object.keys(checkErrors).length) {
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = async () => {
        if (!isValid()) {
            return;
        }

        try {
            setSubmitting(true);

            const phoneNumberParts = phoneNumber.split('-');
            const updatedForm = {
                ...registrant,
                email_address: emailAddress,
                email_address_optin: emailAddressOptin,
                phone_number_area: phoneNumberParts[0],
                phone_number: `${phoneNumberParts[1]}${phoneNumberParts[2]}`,
                phone_number_optin: checkboxValues.includes('optin') || false,
                phone_type: phoneType,
            };
            const responseID = generateRegistrationID();
            const pubKey = await getPublicKey();
            const encryptedResult = createFormPayload(updatedForm, registrationType, 'en', pubKey.public_key);

            sendSurveyData(responseID, null, 'form', encryptedResult.key, encryptedResult.payload).then(() => {
                //console.log('OptInScreen: sendSubmission Success');
            }).catch(async (error) => {
                //console.log('OptInScreen: sendSubmission Fail (send to SQLite)');
                alert('An error occurred while submitting the form.  Please try again.');
                //Bugsnag.notify(error);
            });

            setSubmitting(false);
            onSubmit();
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <div className="relative">
            {submitting &&
                <div className="rounded-lg absolute w-full h-full justify-center items-center z-10 overflow-hidden">
                    <div className="rounded-lg bg-[#7395D4] absolute w-full h-full opacity-30"></div>
                    <Spinner />
                </div>
            }
            <form noValidate>
                <div>
                    <InputText
                        label={formLabels[currentLanguage].email_address}
                        name="email_address"
                        errors={errors}
                        placeholder="jane@email.com"
                        value={emailAddress}
                        onChange={e => setEmailAddress(e.target.value)}
                        onFocus={() => {
                            setErrors(current => {
                                const {email_address, ...newErrors} = current;
                                return newErrors;
                            });
                        }}
                    />
                </div>
                <div className="flex flex-col sm:flex-row">
                    <div className="flex-1">
                        <InputText
                            label={formLabels[currentLanguage].phone_number}
                            name="phone_number"
                            errors={errors}
                            placeholder="555-555-5555"
                            value={phoneNumber}
                            maskType={'phone'}
                            onChange={e => setPhoneNumber(e.target.value)}
                            onFocus={() => {
                                setErrors(current => {
                                    const {phone_number, ...newErrors} = current;
                                    return newErrors;
                                });
                            }}
                        />
                    </div>
                    <div className="flex-1">
                        <InputDropdown
                            name="phone_type"
                            label="&nbsp;"
                            errors={errors}
                            options={[
                                {
                                    label: formLabels[currentLanguage].mobile_phone,
                                    value: 'cell'
                                }, {
                                    label: formLabels[currentLanguage].home_phone,
                                    value: 'home'
                                }
                            ]}
                            hideLabel
                            placeholder={formLabels[currentLanguage].phone_type}
                            onValueChange={value => {
                                setErrors(current => {
                                    const {phone_type, ...newErrors} = current;
                                    return newErrors;
                                });
                                setPhoneType(value);
                            }}
                            selectedValue={phoneType}
                        />
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row">
                    <div className="flex-1 pt-3 pl-3">
                        <CheckboxList value={checkboxValues} size="small" onChange={values => {
                            setCheckboxValues(values)}} width="100%" items={[
                            { label: formLabels[currentLanguage].opt_in_text, value: 'optin' },
                        ]} />
                    </div>
                    <div className="flex-1">

                    </div>
                </div>
                <div className="px-2">
                    <Caption textAlign="right">
                        {formLabels[currentLanguage].required}
                    </Caption>
                    <div className="flex flex-col-reverse sm:flex-row mt-8 items-end sm:items-center justify-end">
                        <div>
                            <TextButton onClick={() => navigate('/thank-you', { state: { type: 'not_eligible_no_followup' } })} title={buttons[currentLanguage].dont_followup} />
                        </div>
                        <div className="ml-4">
                            <PrimaryButton onClick={handleSubmit} title={buttons[currentLanguage].submit} />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

OptInForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default OptInForm;
