import * as React from 'react';
import Heading1 from '../components/typography/Heading1';
import Paragraph from '../components/typography/Paragraph';
import PrimaryButton from '../components/buttons/PrimaryButton';
import RadioButtonList from '../components/inputs/RadioButtonList';
import { useSelector, useDispatch } from 'react-redux';
import { updateRegistrant } from '../store/registrant/registrantSlice';
import { useNavigate } from 'react-router-dom';
import Wrapper from "../components/layout/Wrapper";
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import { formErrors, buttons } from '../content';
import InputError from '../components/inputs/InputError';

const content = {
  "en-US": {
    heading: "Do you have a current Wisconsin ID?",
    content: "The easiest way for us to register you is with your WI Driver License or WI DOT issued ID, but we can also do so if you have a social security number.",
    has_id: "I have an unexpired and valid WI Driver License or WI DOT issued ID with me right now.",
    no_id: "I have an unexpired and valid WI Driver License or WI DOT issued ID, but I don't have it with me right now.",
    has_ssn: "I do not have a valid WI Driver License or WI DOT issued ID, but I have a Social Security Number.",
    no_id_ssn: "I have neither a valid WI Driver License/ID nor a Social Security Number.",
  },
  "es-ES": {
    heading: "¿Tiene una ID de Wisconsin actual?",
    content: "La forma más fácil de registrarlo es con su licencia de conducir de WI o una ID emitida por el DOT de WI, pero también podemos hacerlo si usted tiene un número de seguro social.",
    has_id: "Tengo una licencia de conducir de WI o una ID emitida por el DOT de WI vigente conmigo ahora.",
    no_id: "Tengo una licencia de conducir de WI o una ID emitida por el DOT de WI vigente pero no la tengo conmigo ahora.",
    has_ssn: "No tengo una licencia de conducir de WI o una ID emitida por el DOT de WI válida, pero tengo un número de seguro social.",
    no_id_ssn: "No tengo una licencia de conducir ni una ID de WI, ni tampoco un número de seguro social.",
  }
};

function IDCheckPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const partnerInfo = useSelector((state) => state.partner);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const registrant = useSelector((state) => state.registrant);

  const [value, setValue] = React.useState(null);
  const [hasError, setError] = React.useState(false);

  React.useEffect(() => {
    populateRadios();
  }, [registrant]);

  React.useEffect(() => {
    populateRadios();
  }, []);

  const populateRadios = () => {
    const {
      identity_type,
    } = registrant;

    setValue(identity_type);
  };

  const nextQuestion = () => {
    if (value === 'none' || !value) {
      setError(true);
      return;
    }

    dispatch(updateRegistrant({
      identity_type: value
    }));

    switch (value) {
      case 'has_id':
        if (partnerInfo.can_register_online) {
          navigate('/register-online');
        } else if (partnerInfo.can_register_offline && !partnerInfo.offline_disabled) {
          navigate('/id-information');
        } else {
          alert(partnerInfo.no_register_message);
        }
        break;
      case 'has_ssn':
        if (partnerInfo.can_register_offline && !partnerInfo.offline_disabled) {
          navigate('/id-information');
        } else {
          alert(partnerInfo.no_register_offline_message);
        }
        break;
      case 'no_id_ssn':
        if (partnerInfo.can_register_offline && !partnerInfo.offline_disabled) {
          navigate('/opt-in', { state: { registrationType: 'not_eligible' } });
        } else {
          alert(partnerInfo.no_register_offline_message);
        }
        break;
      default:
        // console.log(`Sorry, we are out of ${value}.`);
    }
  };

  const onChange = (value) => {
    setError(false);
    setValue(value);
  };

  return (
    <Wrapper>
      <ContentLeft>
        <Heading1>{content[currentLanguage].heading}</Heading1>
        <Paragraph>{content[currentLanguage].content}</Paragraph>
      </ContentLeft>
      <ContentRight>
        <div className="px-4">
          <div>
            <RadioButtonList value={value} onChange={onChange} pr={6} width="100%" items={[
              { label: content[currentLanguage].has_id, value: 'has_id' },
              { label: content[currentLanguage].has_ssn, value: 'has_ssn' },
              { label: content[currentLanguage].no_id_ssn, value: 'no_id_ssn' }
            ]} />

            {hasError &&
              <InputError>
                <strong>  {formErrors[currentLanguage].choose_option}</strong>
              </InputError>
            }
          </div>
          <div className="flex w-full flex-row mt-8 items-center justify-end">
            <div>
            </div>
            <div className="ml-4">
              <PrimaryButton onClick={nextQuestion} title={buttons[currentLanguage].next_step} />
            </div>
          </div>
        </div>
      </ContentRight>
    </Wrapper>
  );
}

export default IDCheckPage;
