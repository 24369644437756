import React from 'react'

const InputLabel = ({ children, isRequired, ...props }) => {
    return (
        <label className="mb-1 text-sm font-heading text-[#13187C] uppercase font-black tracking-wider min-h-[20px]">
            {children} {isRequired && <span className="text-[#B80F00]">*</span>}
        </label>
    )
}

export default InputLabel
