import React from "react";
import Heading1 from '../components/typography/Heading1';
import Paragraph from '../components/typography/Paragraph';
import PrimaryButton from "../components/buttons/PrimaryButton";
import TextButton from "../components/buttons/TextButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { clearCurrentRegistrant } from '../store/registrant/registrantSlice'
import { generateRegistrationID, createFormPayload, sendSurveyData, getPublicKey } from '../services/api/voter';
import { changeStep } from "../store/settings/settingsSlice";
import Bugsnag from '@bugsnag/js'
import Wrapper from "../components/layout/Wrapper";
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import { formErrors, buttons } from '../content';

const content = {
  "en-US": {
    heading: "You can register online!",
    content: "It looks like you can register to vote online! Click below and we will pop open the myvote.wi site to start the registration process. Please make sure your pop-ups are turned on.",
    confirmation: "Help us confirm your registration has been added to the voter rolls by entering your address information here:",
  },
  "es-ES": {
    heading: "¡Se puede registrar en línea!",
    content: "¡Parece que puede registrarse para votar en línea! Dé clic a continuación y se abrirá el sitio myvote.wi para comenzar el proceso de registro. Asegúrese de que sus ventanas emergentes estén activadas.",
    confirmation: "Ayúdenos a confirmar que su registro se haya agregado al padrón electoral ingresando la información de su dirección aquí:",
  }
};

const RegisterOnlinePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const registrant = useSelector((state) => state.registrant);
  const partnerInfo = useSelector((state) => state.partner);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  const [newWindowOpened, setNewWindowOpened] = React.useState(false);

  const registerOnline = () => {
    setNewWindowOpened(true);
    window.open('https://myvote.wi.gov/en-US/RegisterToVote', '_blank');
  };

  const finishedOnline = async () => {
    const responseID = generateRegistrationID();
    const pubKey = await getPublicKey();
    const encryptedResult = createFormPayload(registrant, 'registration_online', 'en', pubKey.public_key);

    sendSurveyData(responseID, null, 'form', encryptedResult.key, encryptedResult.payload).then(() => {
      navigate('/thank-you', { state: { type: 'online_registration' } });
      //dispatch(clearCurrentRegistrant());
    }).catch(async (error) => {
      alert('An error occurred while submitting the form.  Please try again.');
      Bugsnag.notify(error);
    });
  };

  const unableToFinish = () => {
    if (partnerInfo.can_register_offline && !partnerInfo.offline_disabled) {
      navigate('/id-information');
    } else {
      alert(partnerInfo.no_register_offline_message);
    }
  };

  React.useEffect(() => {
    dispatch(changeStep([4, 6]));
  }, []);

  return (
    <Wrapper>
      <ContentLeft>
        <Heading1>{content[currentLanguage].heading}</Heading1>
      </ContentLeft>
      <ContentRight>
        {!newWindowOpened && (
          <>
            <Paragraph mb={4}>{content[currentLanguage].content}</Paragraph>
            <div className="flex flex-col items-center justify-end py-12">
              <PrimaryButton onClick={registerOnline} title={buttons[currentLanguage].register_online} />
            </div>
          </>
        )}
        {newWindowOpened && (
          <>
            <Paragraph mb={4}>{content[currentLanguage].confirmation}</Paragraph>
            <div className="flex flex-col items-center justify-end py-12">
              <PrimaryButton onClick={finishedOnline} title={buttons[currentLanguage].i_am_registered} />
              <TextButton onClick={unableToFinish} title={buttons[currentLanguage].couldnt_complete_online} />
            </div>
          </>
        )}
      </ContentRight>
    </Wrapper>
  );
}

export default RegisterOnlinePage;