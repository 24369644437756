import React from 'react';
import LastFourForm from './LastFourForm';
import { useSelector } from 'react-redux';

const LastFourFormWrapper = ({onSubmit, onCancel}) => {
    const registrant = useSelector((state) => state.registrant);
    const [code, onChangeCode] = React.useState('');

    React.useEffect(() => {
        const {
            last_four_ssn
        } = registrant;

        onChangeCode(last_four_ssn);
    }, [registrant]);

    const handleSubmit = () => {
        onSubmit({
            last_four_ssn: code
        });
    };
    return (
        <LastFourForm updateMode={true} onChangeCode={onChangeCode} onSubmit={handleSubmit} onCancel={onCancel} />
    )
}

export default LastFourFormWrapper;
