import * as React from 'react';
import CheckboxList from '../components/inputs/CheckboxList';
import PrimaryButton from '../components/buttons/PrimaryButton';
import TextButton from "../components/buttons/TextButton";
import TextArea from '../components/inputs/TextArea';
import { useSelector } from 'react-redux';
import { buttons } from '../content';

const content = {
    "en-US": {
        question_1: "I am a member of the military.",
        question_2: "I live permanently overseas with no intent to return to the United States.",
        question_3: "I am interested in becoming a poll worker.",
        question_4: "Accommodation needed at poll location:",
        accommodations_placeholder: "e.g. wheelchair access",
    },
    "es-ES": {
        question_1: "Soy un miembro del ejército.",
        question_2: "Vivo en el extranjero en forma permanente y no pretendo regresar a los Estados Unidos.",
        question_3: "Estoy interesado en ser un trabajador electoral.",
        question_4: "Adaptaciones necesarias en el centro electoral:",
        accommodations_placeholder: "p. ej., acceso para silla de ruedas",
    }
};

function AdditionalQuestionsForm({ onSubmit, onCancel }) {
    const registrant = useSelector((state) => state.registrant);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const [formData, setData] = React.useState({});
    const [checkboxValues, setCheckboxValues] = React.useState([]);
    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {
        const {
            is_military,
            is_overseas,
            is_interested_poll_worker,
            accommodations,
        } = registrant;

        setCheckboxValues([
            is_military ? 'is_military' : null,
            is_overseas ? 'is_overseas': null,
            is_interested_poll_worker ? 'is_interested_poll_worker' : null,
        ].filter(value => value));

        setData({
            accommodations,
        });
    }, [registrant]);

    const nextStep =  () => {
        onSubmit({
            ...formData,
            is_military: checkboxValues.includes('is_military'),
            is_overseas: checkboxValues.includes('is_overseas'),
            is_interested_poll_worker: checkboxValues.includes('is_interested_poll_worker'),
        });
    };

    return (
        <div className="w-full p-12 items-start" width="100%">
            <CheckboxList value={checkboxValues} onChange={values => {
                setCheckboxValues(values)}} width="100%" items={[
                    { label: content[currentLanguage].question_1, value: 'is_military' },
                    { label: content[currentLanguage].question_2, value: 'is_overseas' },
                    { label: content[currentLanguage].question_3, value: 'is_interested_poll_worker' },
            ]} />
            <div className="mt-4 w-full">
                <TextArea
                    label={content[currentLanguage].question_4}
                    name="accommodations"
                    errors={errors}
                    placeholder={content[currentLanguage].accommodations_placeholder}
                    onChange={e => {
                        setData({ ...formData, accommodations: e.target.value });
                    }}
                    onBlur={() => {
                        const newErrors = delete errors.accommodations;
                        setErrors({
                            ...newErrors
                        })
                    }}
                    // // onSubmitEditing={handleSubmit}
                    value={formData.accommodations}
                />
            </div>
            <div className="w-full items-end mt-4">
                <div className="flex flex-row mt-3 items-center justify-end">
                    <div>
                        <TextButton onClick={onCancel} title={buttons[currentLanguage].cancel} />
                    </div>
                    <div className="ml-4">
                        <PrimaryButton
                            onClick={nextStep}
                            title={buttons[currentLanguage].update_information}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdditionalQuestionsForm;
