import React from "react";
import { useSelector } from 'react-redux';
import Heading1 from '../components/typography/Heading1';
import Paragraph from '../components/typography/Paragraph';
import Wrapper from "../components/layout/Wrapper";
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";

function TermsPage() {
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);

    return (
        <Wrapper>
            <ContentLeft>
                <Heading1>
                    Terms of Use
                </Heading1>
            </ContentLeft>
            <ContentRight>
                <div className="px-2 sm:px-2 lg:px-0">

                    <Paragraph>This Privacy Policy and the Terms of Use apply to use by organizations, canvassers and registrants of the See Bot Run custom application for registration to vote in the State of Wisconsin
                        (the "App").</Paragraph>

                    <Paragraph>The App is licensed by its owner See Bot Run ("SBR"), to certain organizations which are authorized to sublicense it to other organizations ("Organizations"), for use by their employees
                        and/or volunteers ("Canvassers") to register people to vote in Wisconsin ("Registrants").   SBR may modify this policy from time to time, so we encourage you to check this page when using the
                        App.  By using this App, you agree to the terms of this Privacy Policy and to the Terms of Use.</Paragraph>

                    <Paragraph centered><strong className="underline">TERMS OF USE</strong></Paragraph>

                    <Paragraph><strong>1. Access to App.</strong>  Each Canvasser accessing the App warrants and represents to SBR that the Canvasser is authorized by an organization to use the App.  When each
                        Canvasser registers to use the App, the Canvasser represents that the information provided is accurate, complete and current.</Paragraph>

                    <Paragraph>Canvasser is responsible for safeguarding the Partner Code and password used to access the App and agrees that Canvasser will not disclose the Partner Code or
                        password to any other person or entity.  Canvasser must notify the Organization for which Canvasser is an employee, contractor or volunteer immediately upon becoming aware of any breach of security
                        or unauthorized use of Canvasser's account.  Organization must then immediately notify the entity that sublicensed use of the App to Organization.</Paragraph>

                    <Paragraph><strong>2. Intellectual Property.</strong>  Each user of the App and each Organization and Canvasser agrees that all copyright and other intellectual property rights in and to the software
                        through which the App operates, in any form, object code or source code, and the architecture, features and functionality of the App, are and will remain the sole and exclusive property of SBR.  The
                        name and marks of SBR may not be used by any person or entity without the express prior written consent of SBR.</Paragraph>

                    <Paragraph><strong>3. Termination.</strong> SBR may terminate the account of any Canvasser or terminate access of Organization or Canvasser to the App, without prior notice or liability, for any breach of
                        the Privacy Policy or Terms of Use.</Paragraph>

                    <Paragraph><strong>4. Limitation of Liability.</strong>  In no event will SBR be liable for any damages, costs, fines or penalties incurred by Organization or any Canvasser as a result of use of the
                        App.  To the maximum extent permitted by applicable law, in no event shall SBR be liable for any special, incidental, indirect or consequential damages whatsoever (including but not limited to, damages
                        for loss of profits, loss of data or other information, for business interruptions, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the App,
                        third party software and or third party hardware used with the App or on  which the App is operated, even if SBR or any supplier has been advised of the possibility of such damages and even if the
                        remedy fails of its essential purpose.</Paragraph>

                    <Paragraph>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages which means that some of the above
                        limitations may not apply.  In these states, each party's liability will be limited to the greatest extent permitted by law.</Paragraph>

                    <Paragraph>As between SBR and each Organization and Canvasser, the APP is provided “as is” and “as available” and with all faults and effects without warranty of any kind.  To
                        the maximum extent permitted under applicable law, SBR, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all
                        warranties, whether express, implied, statutory or otherwise, with respect to the App, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement,
                        and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, SBR provides no warranty or undertaking, and makes no
                        representation of any kind that the App will meet the  requirements of any Organization, achieve any intended results, be compatible or work with any other software, applications, systems or
                        services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</Paragraph>

                    <Paragraph>Without limiting the foregoing, neither SBR nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the
                        operation or availability of the App, or the information, content, and materials or products included thereon; (ii) that the App will be uninterrupted or error-free; (iii) as to the accuracy,
                        reliability, or currency of any information or content provided through the App; or (iv) that the App, its servers, the content, or e-mails sent from or on behalf of SBR are free of viruses, scripts,
                        trojan horses, worms, malware, timebombs or other harmful components.</Paragraph>

                    <Paragraph>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the
                        above exclusions and limitations may not apply. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under
                        applicable law.</Paragraph>

                    <Paragraph><strong>5. Governing Law.</strong>  These Terms will be governed by and construed in accordance with the laws of the State of Wisconsin.</Paragraph>

                    <Paragraph><strong>6. Severability.</strong> If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such
                        provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</Paragraph>

                    <Paragraph><strong>7. Waiver.</strong> Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise
                        such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.</Paragraph>
             
                </div>
            </ContentRight>
        </Wrapper>
    );
}


export default TermsPage;