import React from 'react';
import HeaderLogo from './HeaderLogo';
import { changeLanguage } from '../../store/settings/settingsSlice';
import { useDispatch, useSelector } from 'react-redux';

const Header = (props) => {
    const partnerName = useSelector((state) => state.partner.name);
    const logoImageData = useSelector((state) => state.partner.logo_image);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const dispatch = useDispatch();

    const changeCurrentLanguage = () => {
        if (currentLanguage == 'en-US') {
            dispatch(changeLanguage('es-ES'));
        } else {
            dispatch(changeLanguage('en-US'));
        }
    }

    return (
        <header>
            <div className="px-4 md:px-0 md:max-w-[80%] mx-auto flex flex-row items-center bg-white w-full py-4">
                <div className="flex-1"></div>
                <div className="flex-1">
                    <HeaderLogo partnerName={partnerName} imageData={logoImageData} />
                </div>
                <div className="flex flex-row flex-1 justify-end">
                    <button className="uppercase font-body font-black text-[#11156f]" type="button" onClick={changeCurrentLanguage}>
                        {currentLanguage == 'en-US' ? `Español` : `English`}
                    </button>
                </div>
            </div>
        </header>
    )
}

export default Header;
