import * as React from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

const CheckboxList = ({ items, size, onChange, ...props }) => {
    const updateChecked = (value) => {
        if (props.value.includes(value)) {
            onChange(props.value.filter((item) => item !== value));
        } else {
            onChange([...props.value, value]);
        }
    }

    return (
        <div className="flex flex-col w-full gap-4">
            {items.map((item, index) => {
                return (
                    size === 'small' ? (
                        <div key={item.value} className='flex items-center'>
                            <Checkbox.Root
                                key={`${item.value}-${index}`}
                                className={`shadow-sm flex-shrink-0 flex h-[25px] w-[25px] border-[#13187C] border-2 appearance-none items-center justify-center rounded-[4px] outline-none ${props.value.includes(item.value) ? 'bg-[#13187C] text-[white]' : 'bg-white'}`}
                                id={`${item.value}-${index}`}
                                value={item.value}
                                checked={props.value.includes(item.value)}
                                onCheckedChange={(e) => {
                                    updateChecked(item.value)
                                }}
                            >
                                <Checkbox.Indicator>
                                    <CheckIcon className='w-auto h-[25px]' />
                                </Checkbox.Indicator>
                            </Checkbox.Root>
                            <label htmlFor={`${item.value}-${index}`} className="flex-grow text-sm leading-none text-[#13187C] ml-2">{item.label}</label>
                        </div>
                    ) : (
                        <div key={item.value} className='flex items-center'>
                            <Checkbox.Root
                                key={`${item.value}-${index}`}
                                className={`shadow-sm flex-shrink-0 flex h-[30px] w-[30px] border-[#13187C] border-2 appearance-none items-center justify-center rounded-[4px] outline-none ${props.value.includes(item.value) ? 'bg-[#13187C] text-[white]' : 'bg-white'}`}
                                id={`${item.value}-${index}`}
                                value={item.value}
                                checked={props.value.includes(item.value)}
                                onCheckedChange={(e) => {
                                    updateChecked(item.value)
                                }}
                            >
                                <Checkbox.Indicator>
                                    <CheckIcon className='w-auto h-[35px]' />
                                </Checkbox.Indicator>
                            </Checkbox.Root>
                            <label htmlFor={`${item.value}-${index}`} className="flex-grow text-md leading-6 text-[#13187C] ml-4">{item.label}</label>
                        </div>
                    )
                )
            })}
        </div>
    );
};

export default CheckboxList;