import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import InputText from '../components/inputs/InputText';
import PrimaryButton from '../components/buttons/PrimaryButton';
import TextButton from "../components/buttons/TextButton";
import { makeDate, isEmpty, isValidDate, isValidIDNumber } from '../helpers';
import { formLabels, formErrors, buttons } from '../content';
import Spinner from '../components/icons/Spinner';

const IDForm = ({ onSubmit, onCancel, ...props }) => {
    const registrant = useSelector((state) => state.registrant);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const {
        wi_license_id,
        identity_type
    } = registrant;
    const [formData, setData] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        setData({
            wi_license_id,
            id_expiration_date: makeDate(registrant.wi_license_expiration_month, registrant.wi_license_expiration_day, registrant.wi_license_expiration_year),
        });
    }, [registrant, wi_license_id]);

    const isValid = () => {
        let checkErrors = {};
        const [expireMonth, expireDay, expireYear] = formData.id_expiration_date.split('/');

        if (isEmpty(formData.wi_license_id)) {
            checkErrors = {
                ...checkErrors,
                wi_license_id: formErrors[currentLanguage].valid_id,
            };
        } else if (!isValidIDNumber(formData.wi_license_id)) {
            checkErrors = {
                ...checkErrors,
                wi_license_id: formErrors[currentLanguage].valid_id,
            };
        }

        if (isEmpty(formData.id_expiration_date)) {
            checkErrors = {
                ...checkErrors,
                id_expiration_date: formErrors[currentLanguage].valid_id_expiration,
            };
        } else if (!isValidDate(formData.id_expiration_date)) {
            checkErrors = {
                ...checkErrors,
                id_expiration_date: formErrors[currentLanguage].valid_id_expiration,
            };
        }

        setErrors(checkErrors);

        if (!!Object.keys(checkErrors).length) {
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = async () => {
        if (!isValid()) {
            return;
        }

        try {
            setSubmitting(true);

            const [expireMonth, expireDay, expireYear] = formData.id_expiration_date.split('/');
            const {
                wi_license_id,
            } = formData;
            
            onSubmit({
                wi_license_id,
                wi_license_expiration_month: expireMonth,
                wi_license_expiration_day: expireDay,
                wi_license_expiration_year: expireYear,
            });
            
            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <div className="relative">
            {submitting &&
                <div className="rounded-lg absolute w-full h-full justify-center items-center z-10 overflow-hidden">
                    <div className="rounded-lg bg-[#7395D4] absolute w-full h-full opacity-30"></div>
                    <Spinner />
                </div>
            }
            <div className="p-8">
                <div>
                    <InputText
                        label={formLabels[currentLanguage].wi_license_id}
                        required
                        name="wi_license_id"
                        errors={errors}
                        placeholder="S123-4567-6501-00"
                        onChange={e => {
                            setData({ ...formData, wi_license_id: e.target.value });
                        }}
                        onBlur={() => {
                            const newErrors = delete errors.wi_license_id;
                            setErrors({
                                ...newErrors
                            })
                        }}
                        maskType="id"
                        value={formData.wi_license_id}
                    />
                </div>
                <div>
                    <InputText
                        label={formLabels[currentLanguage].id_expiration_date}
                        required
                        name="id_expiration_date"
                        errors={errors}
                        placeholder="mm/dd/yyyy"
                        onChange={e => {
                            setData({ ...formData, id_expiration_date: e.target.value });
                        }}
                        onBlur={() => {
                            const newErrors = delete errors.id_expiration_date;
                            setErrors({
                                ...newErrors
                            })
                        }}
                        maskType="dob"
                        value={formData.id_expiration_date}
                    />
                </div>
                <div className="px-1 mt-4">
                    <div className="flex flex-row mt-3 items-center justify-end">
                        <div>
                            <TextButton onClick={onCancel} title={buttons[currentLanguage].cancel} />
                        </div>
                        <div className="ml-4">
                            <PrimaryButton
                                onClick={handleSubmit}
                                title={buttons[currentLanguage].update_information}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

IDForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default IDForm
