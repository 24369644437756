import React from 'react'

const InputError = ({ children }) => {
    return (
        <span className="text-[#B80F00] text-sm mt-1 px-2">
            {children}
        </span>
    )
}

export default InputError
