import React from 'react';
import PropTypes from 'prop-types';

function PrimaryButton({ onClick, title, children, ...props }) {
  return (
    <button type="button" className="rounded-full bg-[#B80F00] disabled:bg-slate-400 disabled:opacity-25 disabled:cursor-not-allowed text-white tracking-wide font-heading font-bold uppercase shadow-md px-8 py-3 text-md" onClick={onClick} {...props}>
      {title ? title : children}
    </button>
  );
}

PrimaryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default PrimaryButton;
