import React from 'react'

const Spinner = () => {
  return (
    <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default Spinner
