import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import InputText from '../components/inputs/InputText';
import InputDropdown from '../components/inputs/InputDropdown';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Caption from '../components/typography/Caption';
import { isEmpty } from '../helpers';
import { formLabels, formErrors, buttons } from '../content';
import Spinner from '../components/icons/Spinner';

const NewNameForm = ({ onSubmit }) => {
    const registrant = useSelector((state) => state.registrant);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const {
        new_first_name,
        new_last_name,
        new_middle_name,
        new_suffix,
    } = registrant;

    const [formData, setData] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        setData({
            new_first_name,
            new_last_name,
            new_middle_name,
            new_suffix,
        });
    }, [registrant]);

    const isValid = () => {
        let checkErrors = {};

        if (isEmpty(formData.new_first_name)) {
            checkErrors = {
                ...checkErrors,
                new_first_name: formErrors[currentLanguage].required_first_name,
            };
        } else if (formData.new_first_name.length > 50) {
            checkErrors = {
                ...checkErrors,
                new_first_name: formErrors[currentLanguage].valid_first_name,
            };
        }

        if (formData.new_middle_name.length > 50) {
            checkErrors = {
                ...checkErrors,
                new_middle_name: formErrors[currentLanguage].valid_middle_name,
            };
        }

        if (isEmpty(formData.new_last_name)) {
            checkErrors = {
                ...checkErrors,
                new_last_name: formErrors[currentLanguage].required_last_name,
            };
        } else if (formData.new_last_name.length > 50) {
            checkErrors = {
                ...checkErrors,
                new_last_name: formErrors[currentLanguage].valid_last_name,
            };
        }

        setErrors(checkErrors);

        if (!!Object.keys(checkErrors).length) {
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = async () => {
        if (!isValid()) {
            return;
        }

        const {
            new_first_name,
            new_last_name,
            new_middle_name,
            new_suffix,
        } = formData;

        try {
            setSubmitting(true);

            onSubmit({
                new_first_name,
                new_last_name,
                new_middle_name,
                new_suffix,
            });
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <div className="relative">
            {submitting &&
                <div className="rounded-lg absolute w-full h-full justify-center items-center z-10 overflow-hidden">
                    <div className="rounded-lg bg-[#7395D4] absolute w-full h-full opacity-30"></div>
                    <Spinner />
                </div>
            }
            <div className="p-8">
                <div className="flex flex-row">
                    <div className="flex-1">
                        <InputText
                            label={formLabels[currentLanguage].first_name}
                            required
                            name="new_first_name"
                            errors={errors}
                            placeholder="Jane"
                            onChange={e => {
                                setData({ ...formData, new_first_name: e.target.value });
                            }}
                            onBlur={() => {
                                setErrors(current => {
                                    const {new_first_name, ...newErrors} = current;
                                    return newErrors;
                                });
                            }}
                            // onSubmitEditing={handleSubmit}
                            value={formData.new_first_name}
                        />
                    </div>
                    <div className="flex-1">
                        <InputText
                            label={formLabels[currentLanguage].last_name}
                            required
                            name="new_last_name"
                            errors={errors}
                            placeholder="Doe"
                            onChange={e => {
                                setData({ ...formData, new_last_name: e.target.value });
                            }}
                            onBlur={() => {
                                setErrors(current => {
                                    const {new_last_name, ...newErrors} = current;
                                    return newErrors;
                                });
                            }}
                            // onSubmitEditing={handleSubmit}
                            value={formData.new_last_name}
                        />
                    </div>
                </div>

                <div className="flex flex-row">
                    <div className="flex-1">
                        <InputText
                            label={formLabels[currentLanguage].middle_name}
                            name="new_middle_name"
                            errors={errors}
                            placeholder="Henry"
                            onChange={e => {
                                setData({ ...formData, new_middle_name: e.target.value });
                            }}
                            onBlur={() => {
                                setErrors(current => {
                                    const {new_middle_name, ...newErrors} = current;
                                    return newErrors;
                                });
                            }}
                            // onSubmitEditing={handleSubmit}
                            value={formData.new_middle_name}
                        />
                    </div>
                    <div className="flex-1">
                        <InputDropdown
                            name="new_suffix"
                            label={formLabels[currentLanguage].suffix}
                            errors={errors}
                            placeholder={formLabels[currentLanguage].suffix_eg}
                            options={[
                                { value: "JR", label: "JR" },
                                { value: "SR", label: "SR" },
                                { value: "II", label: "II" },
                                { value: "III", label: "III" },
                                { value: "IV", label: "IV" },
                                { value: "V", label: "V" },
                                { value: "VI", label: "VI" },
                                { value: "VII", label: "VII" },
                            ]}
                            onValueChange={value => {
                                setErrors(current => {
                                    const {new_suffix, ...newErrors} = current;
                                    return newErrors;
                                });
                                setData({ ...formData, new_suffix: value });
                            }}
                            // onSubmitEditing={handleSubmit}
                            selectedValue={formData.new_suffix}
                        />
                    </div>
                </div>
                <div className="px-1">
                    <Caption textAlign="right">
                        {formLabels[currentLanguage].required}
                    </Caption>
                    <div className="flex flex-row mt-8 items-center justify-end">
                        <div>
                        </div>
                        <div className="ml-4">
                            <PrimaryButton onClick={handleSubmit} title={buttons[currentLanguage].next_step} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

NewNameForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default NewNameForm
