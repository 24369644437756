import * as React from 'react';
import CheckboxList from '../components/inputs/CheckboxList';
import PrimaryButton from '../components/buttons/PrimaryButton';
import TextArea from '../components/inputs/TextArea';
import Heading1 from '../components/typography/Heading1';
import { useSelector, useDispatch } from 'react-redux';
import { updateRegistrant } from '../store/registrant/registrantSlice';
import { useNavigate } from "react-router-dom";
import Wrapper from "../components/layout/Wrapper";
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import { buttons } from '../content';

export const content = {
  "en-US": {
    heading: "Please let us know any that apply to you:",
    question_1: "I am a member of the military.",
    question_2: "I live permanently overseas with no intent to return to the United States.",
    question_3: "I am interested in becoming a poll worker.",
    question_4: "Accommodation needed at poll location:",
    accommodations_placeholder: "e.g. wheelchair access",
  },
  "es-ES": {
    heading: "Infórmenos de las que le corresponden:",
    question_1: "Soy un miembro del ejército.",
    question_2: "Vivo en el extranjero en forma permanente y no pretendo regresar a los Estados Unidos.",
    question_3: "Estoy interesado en ser un trabajador electoral.",
    question_4: "Adaptaciones necesarias en el centro electoral:",
    accommodations_placeholder: "p. ej., acceso para silla de ruedas",
  }
};

function AdditionalQuestionsPage() {
  const registrant = useSelector((state) => state.registrant);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const [formData, setData] = React.useState({});
  const [checkboxValues, setCheckboxValues] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    const {
      is_military,
      is_overseas,
      is_interested_poll_worker,
      accommodations,
    } = registrant;

    setCheckboxValues([
      is_military ? 'is_military' : null,
      is_overseas ? 'is_overseas' : null,
      is_interested_poll_worker ? 'is_interested_poll_worker' : null,
    ].filter(value => value));

    setData({
      accommodations,
    });
  }, [registrant]);

  const nextStep = () => {
    dispatch(updateRegistrant({
      ...formData,
      is_military: checkboxValues.includes('is_military'),
      is_overseas: checkboxValues.includes('is_overseas'),
      is_interested_poll_worker: checkboxValues.includes('is_interested_poll_worker'),
    }))

    navigate('/review');
  };

  return (
    <Wrapper>
      <ContentLeft>
        <Heading1>{content[currentLanguage].heading}</Heading1>
      </ContentLeft>
      <ContentRight>
        <CheckboxList value={checkboxValues} onChange={values => {
          setCheckboxValues(values)
        }} width="100%" items={[
          { label: content[currentLanguage].question_1, value: 'is_military' },
          { label: content[currentLanguage].question_2, value: 'is_overseas' },
          { label: content[currentLanguage].question_3, value: 'is_interested_poll_worker' },
        ]} />
        <div className="w-full mt-4">
          <TextArea
            label={content[currentLanguage].question_4}
            name="accommodations"
            errors={errors}
            placeholder={content[currentLanguage].accommodations_placeholder}
            onChange={e => {
              setData({ ...formData, accommodations: e.target.value });
            }}
            onBlur={() => {
              const newErrors = delete errors.accommodations;
              setErrors({
                ...newErrors
              })
            }}
            value={formData.accommodations}
          />
        </div>
        <div className="w-full mt-4 flex justify-end">
          <PrimaryButton onClick={nextStep} title={buttons[currentLanguage].next_step} />
        </div>
      </ContentRight>
    </Wrapper>
  );
}

export default AdditionalQuestionsPage;
