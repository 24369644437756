import React from "react";

const ModalContainer = ({ children, isOpen, medium }) => {
  const maxWidthStyle = medium ? `max-w-[640px]` : "max-w-[960px]";

  return (
    <>
      {isOpen && (
        <div className="fixed w-full h-full top-0 left-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
          <div className={`w-[80%] ${maxWidthStyle} max-h-[80%] bg-white rounded-lg overflow-y-auto mx-auto my-20`}>
            <div className="w-full">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalContainer;
