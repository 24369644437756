import * as React from 'react';
import InputError from '../components/inputs/InputError';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from "../components/layout/Wrapper";
import Paragraph from '../components/typography/Paragraph';
import Heading1 from '../components/typography/Heading1';
import PrimaryButton from '../components/buttons/PrimaryButton';
import RadioButtonList from '../components/inputs/RadioButtonList';
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { useNavigate } from "react-router-dom";
import { formErrors, buttons } from '../content';

export const content = {
    "en-US": {
        heading: "Have you updated your name with the Social Security Administration (SSA)?",
        paragraph: "In order to change your name on your voter registration, you must have updated your name with the Social Security Administration.",
        updated: "Yes, I have updated my name with the Social Security Administration.",
        not_updated: "No, I have not updated my name with the Social Security Administration.",
        unsure: "I don't know if I have updated my name.",
    },
    "es-ES": {
        heading: "¿Ha actualizado su nombre en la Administración del Seguro Social (SSA en inglés)?",
        paragraph: "Para cambiar su nombre en su registro de votante, debe haber actualizado su nombre en la Administración del Seguro Social.",
        updated: "Sí, he actualizado mi nombre en la Administración del Seguro Social.",
        not_updated: "No, no he actualizado mi nombre en la Administración del Seguro Social.",
        unsure: "No sé si he actualizado  mi nombre.",
    }
};

function UpdateNamePage({ navigation }) {
    const registrant = useSelector((state) => state.registrant);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const [value, setValue] = React.useState([]);
    const [hasError, setError] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onChange = (value) => {
        setError(false);
        setValue(value);
    };

    const nextStep = () => {
        if (!value) {
            setError(true);
            return;
        }

        dispatch(updateRegistrant({
            ssa_name_updated: value,
        }));

        if (value === 'updated') {
            navigate('/update-name-form');
        }
        else if (value === 'not_updated') {
            navigate('/name-not-updated');
        }
        else if (value === 'unsure') {
            navigate('/name-not-updated');
        }
    };

    const populateCheckboxes = () => {
        const {
            ssa_name_updated,
        } = registrant;

        setValue(ssa_name_updated);
    };

    React.useEffect(() => {
        populateCheckboxes();
    }, [registrant]);

    React.useEffect(() => {
        dispatch(changeStep([2, 6]));
    }, []);

    return (
        <Wrapper>
            <ContentLeft>
                <Heading1 mb={4}>{content[currentLanguage].heading}</Heading1>
                <Paragraph mb={4}>{content[currentLanguage].paragraph}</Paragraph>
            </ContentLeft>
            <ContentRight>
                <div className="px-2 sm:px-2 lg:px-0x">
                    <div>
                        <RadioButtonList value={value} onChange={onChange} width="100%" items={[
                            { label: content[currentLanguage].updated, value: 'updated' },
                            { label: content[currentLanguage].not_updated, value: 'not_updated' },
                            { label: content[currentLanguage].unsure, value: 'unsure' },
                        ]} />
                        {hasError &&
                            <InputError>
                                <strong>{formErrors[currentLanguage].choose_option}</strong>
                            </InputError>
                        }
                    </div>
                    <div className="w-full mt-4 flex justify-end">
                        <PrimaryButton onClick={nextStep} title={buttons[currentLanguage].next_question} />
                    </div>
                </div>
            </ContentRight>
        </Wrapper>
    );
}

export default UpdateNamePage;
