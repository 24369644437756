import React from "react";
import { useSelector } from 'react-redux';
import Heading1 from '../components/typography/Heading1';
import Paragraph from '../components/typography/Paragraph';
import PrimaryButton from "../components/buttons/PrimaryButton";
import Wrapper from "../components/layout/Wrapper";
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";

export const content = {
  "en-US": {
    heading: "Register to Vote WI",
    content: "Canvassers, use the iOS or Android application to start canvassing and helping to get people registered!",
  },
  "es-ES": {
    heading: "Register to Vote WI",
    content: "Buscadores de simpatizantes, usen la aplicación de iOS o Android para comenzar a buscar simpatizantes y ayudar a conseguir personas que se registren.",
  }
};

const DownloadPage = () => {
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  const goToApp = (platform) => {
    if (platform === 'android') {
      window.open("https://play.google.com/store/apps/details?id=com.registertovotewi.app", "_blank");
    } else {
      window.open("https://apps.apple.com/app/register-to-vote-wi/id1613181258", "_blank");
    }
  };

  return (
    <Wrapper>
      <ContentLeft>
        <Heading1 mb={4}>
          {content[currentLanguage].heading}
        </Heading1>
        <Paragraph mb={4}>
          {content[currentLanguage].content}
        </Paragraph>
      </ContentLeft>
      <ContentRight>
        <div className="px-2 sm:px-2 lg:px-0x">
          <div className="flex flex-col justify-end items-center py-12">
            <div className="py-2 my-2">
              <PrimaryButton onClick={() => goToApp('android')} title="Google Play" py={{ base: 2 }} my={{ base: 4 }} />
            </div>
            <div className="py-2 my-2">
              <PrimaryButton onClick={() => goToApp('ios')} title="App Store" py={{ base: 2 }} my={{ base: 4 }} />
            </div>
          </div>
        </div>
      </ContentRight>
    </Wrapper>
  );
}

export default DownloadPage;