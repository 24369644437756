import React from "react";

const ContentColumn = ({ children }) => {
  return (
    <div className="w-full py-6 bg-[#F4F7F9]">
      <div className="w-full mx-auto md:w-4/5" mx="auto">{children}</div>
    </div>
  );
};

const ContentColumnHeader = ({ children }) => {
  return <>{children}</>;
};

const ContentColumnContent = ({ children }) => {
  return <div className="w-full">{children}</div>;
};

ContentColumn.Header = ContentColumnHeader;
ContentColumn.Content = ContentColumnContent;

export default ContentColumn;
