import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import InputText from "../components/inputs/InputText";
import InputDropdown from "../components/inputs/InputDropdown";
import PrimaryButton from "../components/buttons/PrimaryButton";
import TextButton from "../components/buttons/TextButton";
import Caption from "../components/typography/Caption";
import isEmail from "validator/lib/isEmail";
import { makeDate, makePhoneNumber, isEmpty, isValidDate, isValidAge, isValidPhoneNumber } from "../helpers";
import { formLabels, formErrors, buttons } from '../content';

const PersonalInformationForm = ({ onSubmit, onCancel }) => {
  const registrant = useSelector((state) => state.registrant);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const [formData, setData] = React.useState({});
  const [errors, setErrors] = React.useState({});

  const {
    first_name,
    middle_name,
    suffix,
    last_name,
    email_address,
    phone_type,
    has_new_name,
    new_last_name,
    new_first_name,
    new_middle_name,
    new_suffix,
    date_of_birth_month,
    date_of_birth_day,
    date_of_birth_year,
    check_date_of_birth_month,
    check_date_of_birth_day,
    check_date_of_birth_year,
  } = registrant;

  React.useEffect(() => {
    setData({
      first_name: has_new_name ? new_first_name : first_name,
      last_name: has_new_name ? new_last_name : last_name,
      middle_name: has_new_name ? new_middle_name : middle_name,
      suffix: has_new_name ? new_suffix : suffix,
      email_address,
      phone_type,
      date_of_birth: !isEmpty(date_of_birth_month)
        ? makeDate(date_of_birth_month, date_of_birth_day, date_of_birth_year)
        : makeDate(
            check_date_of_birth_month,
            check_date_of_birth_day,
            check_date_of_birth_year
          ),
      phone_number: makePhoneNumber(registrant),
    });
  }, [
    registrant,
    first_name,
    middle_name,
    suffix,
    last_name,
    email_address,
    phone_type,
    has_new_name,
    new_last_name,
    new_first_name,
    new_middle_name,
    new_suffix,
    date_of_birth_month,
    date_of_birth_day,
    date_of_birth_year,
    check_date_of_birth_month,
    check_date_of_birth_day,
    check_date_of_birth_year,
  ]);

  const isValid = () => {
    let checkErrors = {};

    if (isEmpty(formData.first_name)) {
        checkErrors = {
            ...checkErrors,
            first_name: formErrors[currentLanguage].required_first_name,
        };
    } else if (formData.first_name.length > 50) {
        checkErrors = {
            ...checkErrors,
            first_name: formErrors[currentLanguage].valid_first_name,
        };
    }

    if (isEmpty(formData.last_name)) {
        checkErrors = {
            ...checkErrors,
            last_name: formErrors[currentLanguage].required_last_name,
        };
    } else if (formData.last_name.length > 50) {
        checkErrors = {
            ...checkErrors,
            last_name: formErrors[currentLanguage].valid_last_name,
        };
    }

    if (isEmpty(formData.date_of_birth)) {
        checkErrors = {
            ...checkErrors,
            date_of_birth: formErrors[currentLanguage].required_date_of_birth,
        };
    } else if (!isValidDate(formData.date_of_birth)) {
        checkErrors = {
            ...checkErrors,
            date_of_birth: formErrors[currentLanguage].valid_date_of_birth,
        };
    } else if (!isValidAge(formData.date_of_birth)) {
        checkErrors = {
            ...checkErrors,
            date_of_birth: formErrors[currentLanguage].valid_date_of_birth,
        };
    }

    if (!isEmpty(formData.email_address) && !isEmail(formData.email_address)) {
        checkErrors = {
            ...checkErrors,
            email_address: formErrors[currentLanguage].required_email,
        };
    } else if (formData.email_address.length > 100) {
        checkErrors = {
            ...checkErrors,
            email_address: formErrors[currentLanguage].valid_email,
        };
    }

    if (!isEmpty(formData.phone_number.replace('-', '').replace('_', '')) && !isValidPhoneNumber(formData.phone_number)) {
        checkErrors = {
            ...checkErrors,
            phone_number: formErrors[currentLanguage].valid_phone_number,
        };
    }

    setErrors(checkErrors);

    if (!!Object.keys(checkErrors).length) {
        return false;
    } else {
        return true;
    }
  };

  const handleSubmit = () => {
    if (!isValid()) {
      return;
    }

    const {
      first_name,
      middle_name,
      suffix,
      last_name,
      email_address,
      phone_number,
      phone_type,
      date_of_birth,
    } = formData;

    const dobParts = date_of_birth.split("/");
    const phoneNumberParts = phone_number.split("-");

    if (registrant.has_new_name) {
      onSubmit({
        new_first_name: first_name,
        new_middle_name: middle_name,
        new_last_name: last_name,
        new_suffix: suffix,
        email_address,
        phone_number_area: phoneNumberParts[0],
        phone_number: `${phoneNumberParts[1]}${phoneNumberParts[2]}`,
        phone_type,
        date_of_birth_month: dobParts[0],
        date_of_birth_day: dobParts[1],
        date_of_birth_year: dobParts[2],
      });
    } else {
      onSubmit({
        first_name,
        middle_name,
        last_name,
        suffix,
        email_address,
        phone_number_area: phoneNumberParts[0],
        phone_number: `${phoneNumberParts[1]}${phoneNumberParts[2]}`,
        phone_type,
        date_of_birth_month: dobParts[0],
        date_of_birth_day: dobParts[1],
        date_of_birth_year: dobParts[2],
      });
    }
  };

  return (
    <div className="relative">
      <div className="p-8">
        <div className="flex flex-row">
          <div className="flex-1">
            <InputText
              label={formLabels[currentLanguage].first_name}
              required
              name="first_name"
              errors={errors}
              placeholder="Jane"
              value={formData.first_name}
              onChange={(e) => {
                setData({ ...formData, first_name: e.target.value });
              }}
              onFocus={() => {
                setErrors(current => {
                  const {first_name, ...newErrors} = current;
                  return newErrors;
              }); 
              }}
            />
          </div>
          <div className="flex-1">
            <InputText
              label={formLabels[currentLanguage].last_name}
              required
              name="last_name"
              errors={errors}
              placeholder="Doe"
              value={formData.last_name}
              onChange={(e) => {
                setData({ ...formData, last_name: e.target.value });
              }}
              onFocus={() => {
                setErrors(current => {
                  const {last_name, ...newErrors} = current;
                  return newErrors;
              }); 
              }}
            />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex-1">
            <InputText
              label={formLabels[currentLanguage].middle_name}
              name="middle_name"
              errors={errors}
              placeholder="Henry"
              value={formData.middle_name}
              onChange={(e) => {
                setData({ ...formData, middle_name: e.target.value });
              }}
              onFocus={() => {
                setErrors(current => {
                  const {middle_name, ...newErrors} = current;
                  return newErrors;
              }); 
              }}
            />
          </div>
          <div className="flex-1">
            <InputDropdown
              name="suffix"
              label={formLabels[currentLanguage].suffix}
              errors={errors}
              placeholder={formLabels[currentLanguage].suffix_eg}
              options={[
                { value: "JR", label: "JR" },
                { value: "SR", label: "SR" },
                { value: "II", label: "II" },
                { value: "III", label: "III" },
                { value: "IV", label: "IV" },
                { value: "V", label: "V" },
                { value: "VI", label: "VI" },
                { value: "VII", label: "VII" },
              ]}
              onValueChange={(value) => {
                setErrors(current => {
                  const {suffix, ...newErrors} = current;
                  return newErrors;
              }); 
                setData({ ...formData, suffix: value });
              }}
              selectedValue={formData.suffix}
            />
          </div>
        </div>
        <div>
          <InputText
            label={formLabels[currentLanguage].date_of_birth}
            required
            name="date_of_birth"
            errors={errors}
            placeholder="mm/dd/yyyy"
            maskType="dob"
            value={formData.date_of_birth}
            onChange={(e) => {
              setData({ ...formData, date_of_birth: e.target.value });
            }}
            onFocus={() => {
              setErrors(current => {
                const {date_of_birth, ...newErrors} = current;
                return newErrors;
            }); 
            }}
          />
        </div>
        <div>
          <InputText
            label={formLabels[currentLanguage].email_address}
            required
            name="email_address"
            errors={errors}
            placeholder="jane@email.com"
            value={formData.email_address}
            onChange={(e) => {
              setData({ ...formData, email_address: e.target.value });
            }}
            onFocus={() => {
              setErrors(current => {
                const {email_address, ...newErrors} = current;
                return newErrors;
            }); 
            }}
          />
        </div>
        <div className="flex flex-row">
          <div className="flex-1">
            <InputText
              label={formLabels[currentLanguage].phone_number}
              required
              name="phone_number"
              errors={errors}
              placeholder="555-555-5555"
              maskType={"phone"}
              value={formData.phone_number}
              onChange={(e) => {
                setData({ ...formData, phone_number: e.target.value });
              }}
              onFocus={() => {
                setErrors(current => {
                  const {phone_number, ...newErrors} = current;
                  return newErrors;
              }); 
              }}
            />
          </div>
          <div className="flex-1">
            <InputDropdown
              name="phone_type"
              label={formLabels[currentLanguage].phone_type}
              required
              errors={errors}
              options={[
                {
                    label: formLabels[currentLanguage].mobile_phone,
                    value: 'cell'
                }, {
                    label: formLabels[currentLanguage].home_phone,
                    value: 'home'
                }
              ]}
              placeholder={formLabels[currentLanguage].phone_type}
              onValueChange={(value) => {
                setErrors(current => {
                    const {phone_type, ...newErrors} = current;
                    return newErrors;
                }); 
                setData({ ...formData, phone_type: value });
              }}
              selectedValue={formData.phone_type}
            />
          </div>
        </div>
        <div className="px-1">
          <Caption textAlign="right" mb="4">
            {formLabels[currentLanguage].required}
          </Caption>
          <div className="flex flex-row mt-3 items-center justify-end">
            <div></div>
            <div className="ml-4">
              <div className="flex flex-row mt-3 items-center justify-end">
                <div>
                  <TextButton onClick={onCancel} title={buttons[currentLanguage].cancel} />
                </div>
                <div className="ml-4">
                  <PrimaryButton
                    onClick={handleSubmit}
                    title={buttons[currentLanguage].update_information}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PersonalInformationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PersonalInformationForm;
