import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckRegistrationForm from "../forms/CheckRegistrationForm";
import Wrapper from "../components/layout/Wrapper";
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import Heading1 from '../components/typography/Heading1';
import Heading2 from '../components/typography/Heading2';
import Paragraph from '../components/typography/Paragraph';
import PrimaryButton from "../components/buttons/PrimaryButton";
import RadioButtonList from '../components/inputs/RadioButtonList';
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { formErrors, buttons } from '../content';
import InputError from '../components/inputs/InputError';

export const content = {
  "en-US": {
    heading: "Check Your Registration Status",
    content: "We need your current legal name and date of birth to check against the WI voter role. We collect your email and phone number in case we need to contact you regarding your registration.",
    registered_heading: "This is what we found on the WI voter roll. Is this information correct?",
    not_registered_heading: "Hmm, we couldn't find you on the WI Voter Roll.",
    not_registered_paragraph: "Let's get you registered!",
    first_time: "I am registering to vote for the first time.",
    update_name: "I need to update my name, but my address is correct.",
    update_address: "I need to update my address, but my name is correct.",
    update_name_address: "I need to update both my name and address.",
    correct: "Yes, this information is correct!",
  },
  "es-ES": {
    heading: "Verifique el estado de su registro",
    content: "Necesitamos su nombre legal actual y su fecha de nacimiento para verificarlo en el patrón electoral de WI. Recopilamos su correo electrónico y número de teléfono en caso de que necesitemos contactarlo con respecto a su registro.",
    registered_heading: "Esto es lo que encontramos en el patrón electoral de WI. ¿Esta información es correcta?",
    not_registered_heading: "Mmm, no puedo encontrarlo en el patrón electoral de WI.",
    not_registered_paragraph: "¡Vamos a registrarlo!",
    first_time: "Registrar para votar",
    update_name: "Necesito actualizar mi nombre, pero mi dirección es correcta.",
    update_address: "Necesito actualizar mi dirección, pero mi nombre es correcto.",
    update_name_address: "Necesito actualizar mi nombre y mi dirección.",
    correct: "Sí, esta información es correcta.",
  },
};

const CheckRegistrationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const partnerInfo = useSelector((state) => state.partner);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const [isRegistered, setIsRegistered] = React.useState(undefined);
  const [value, setValue] = React.useState(null);
  const [hasError, setError] = React.useState(false);

  React.useEffect(() => {
    dispatch(changeStep([1, 6]));
  }, []);

  const handleCheckRegistration = (values) => {
    setIsRegistered(values);

    const phoneNumberParts = (values.registrantInfo.phone_number !== '' ? values.registrantInfo.phone_number.split('-') : ['', '', '']);

    dispatch(updateRegistrant({
      ssa_name_updated: null,
      has_new_name: false,
      new_last_name: '',
      new_first_name: '',
      new_middle_name: '',
      new_suffix: '',
      dmv_address_updated: null,
      has_new_address: false,
      new_address_1: '',
      new_address_2_type: '',
      new_address_2_number: '',
      new_city: '',
      new_zipcode: '',
      information_correct: null,
      check_first_name: values.registrantInfo.first_name,
      check_last_name: values.registrantInfo.last_name,
      check_date_of_birth_month: values.registrantInfo.dob_month,
      check_date_of_birth_day: values.registrantInfo.dob_day,
      check_date_of_birth_year: values.registrantInfo.dob_year,
      date_of_birth_month: values.registrantInfo.dob_month,
      date_of_birth_day: values.registrantInfo.dob_day,
      date_of_birth_year: values.registrantInfo.dob_year,
      registered: values.registrationStatus ? values.registrationStatus?.registered : false,
      registered_status: values.registrationStatus ? values.registrationStatus?.status : 'unknown',
      email_address: values.registrantInfo.email_address,
      phone_number_area: phoneNumberParts[0],
      phone_number: `${phoneNumberParts[1]}${phoneNumberParts[2]}`,
      phone_number_optin: values.registrantInfo.phone_number_optin,
      phone_type: values.registrantInfo.phone_type,
      first_name: values.registrationStatus?.first_name || '',
      last_name: values.registrationStatus?.last_name || '',
      middle_name: values.registrationStatus?.middle_name || '',
      suffix: values.registrationStatus?.suffix || '',
      address_1: values.registrationStatus?.address_1 || '',
      unit_type: values.registrationStatus?.unit_type || '',
      unit_number: values.registrationStatus?.unit_number || '',
      city: values.registrationStatus?.city || '',
      state: values.registrationStatus?.state || '',
      zipcode: values.registrationStatus?.zipcode || '',
    }));

    dispatch(changeStep([2, 6]));
  };

  const onChange = (value) => {
    setError(false);
    setValue(value);
  };

  const nextStep = () => {
    if (!value) {
      setError(true);
      return;
    }

    dispatch(updateRegistrant({
      information_correct: value,
    }));

    switch (value) {
      case 'correct':
        dispatch(updateRegistrant({
          information_correct: value,
          has_new_name: false,
          has_new_address: false,
        }));

        if (isRegistered?.registrationStatus?.status == 'active') {
          navigate('/opt-in', { state: { registrationType: 'on_roll' } });
        } else {
          navigate('/dob-consent');
        }
        break;
      case 'update_name':
        dispatch(updateRegistrant({
          information_correct: value,
          has_new_name: true,
          has_new_address: false,
        }));

        navigate('/update-name');
        break;
      case 'update_address':
        dispatch(updateRegistrant({
          information_correct: value,
          has_new_name: false,
          has_new_address: true,
        }));

        navigate('update-address');
        break;
      case 'update_name_address':
        dispatch(updateRegistrant({
          information_correct: value,
          has_new_name: true,
          has_new_address: true,
        }));
        navigate('update-name');
        break;
      case 'first_time':
        dispatch(updateRegistrant({
          information_correct: value,
          has_new_name: false,
          has_new_address: false,
        }));

        navigate('/dob-consent');
        break;
      default:
        // console.log('end');
    }
  };

  const register = () => {
    dispatch(updateRegistrant({
      information_correct: 'not_registered',
    }));

    navigate('/dob-consent');
  };

  return (
    <Wrapper>
      <ContentLeft>
        {isRegistered === undefined &&
          <>
            <Heading1 mb={4}>
              {content[currentLanguage].heading}
            </Heading1>
            <Paragraph mb={4}>
              {content[currentLanguage].content}
            </Paragraph>
          </>
        }
        {((isRegistered?.registrationStatus?.status != 'notfound' && isRegistered?.registrationStatus?.status != 'notfound') && isRegistered !== undefined) &&
        <>
          <Heading1>{content[currentLanguage].registered_heading}</Heading1>
          <div className="flex flex-col items-center">
            <Heading2>
              {isRegistered?.registrationStatus?.name}
            </Heading2>
            <Heading2>
              {isRegistered?.registrationStatus?.address}
            </Heading2>
            <Heading2>
              {isRegistered?.registrationStatus?.city}, {isRegistered?.registrationStatus?.zipcode}
            </Heading2>
            <Heading2>
              STATUS: {isRegistered?.registrationStatus?.status}
            </Heading2>
          </div>
        </>
        }
        {((isRegistered?.registrationStatus?.status == 'notfound' || isRegistered?.registrationStatus?.status == 'unknown') && isRegistered !== undefined) &&
          <>
            <Heading1>{content[currentLanguage].not_registered_heading}</Heading1>
            <Paragraph my={4}>{content[currentLanguage].not_registered_paragraph}</Paragraph>
          </>
        }
      </ContentLeft>
      <ContentRight>
        {isRegistered === undefined && partnerInfo.can_register && (
          <CheckRegistrationForm onSubmit={handleCheckRegistration} />
        )}
        {isRegistered === undefined && !partnerInfo.can_register && (
          <>
            <Heading1>{partnerInfo.no_register_message} </Heading1>
          </>
        )}
        {((isRegistered?.registrationStatus?.status != 'notfound') && isRegistered !== undefined) &&
        <div className="px-2 sm:px-2 lg:px-0x">
          <RadioButtonList value={value} onChange={onChange} width="100%" items={[
            { label: content[currentLanguage].correct, value: 'correct' },
            { label: content[currentLanguage].update_name, value: 'update_name' },
            { label: content[currentLanguage].update_address, value: 'update_address' },
            { label: content[currentLanguage].update_name_address, value: 'update_name_address' }
          ]} />

          {value === null && hasError && (
            <InputError>{formErrors[currentLanguage].choose_option}</InputError>
          )}
          <div className="w-full mt-4 flex justify-end">
            <PrimaryButton onClick={nextStep} title={buttons[currentLanguage].next_step} />
          </div>
        </div>
        }
        {((isRegistered?.registrationStatus?.status == 'notfound' || isRegistered?.registrationStatus?.status == 'unknown') && isRegistered !== undefined) &&
        <div className="px-2 sm:px-2 lg:px-0x">
          <div className="flex flex-col justify-end items-center py-12">
            <PrimaryButton onClick={register} title={buttons[currentLanguage].register_to_vote} />
          </div>
        </div>
        }
      </ContentRight>
    </Wrapper>
  );
}

export default CheckRegistrationPage;