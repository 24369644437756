import React from "react";
import { useSelector, useDispatch } from "react-redux";
import FormWrapper from "./FormWrapper";
import ModalContainer from "./ModalContainer";
import PersonalInformationForm from "../../forms/PersonalInformationForm";
import ResidenceInformationForm from "../../forms/ResidenceInformationForm";
import PreviousNameForm from "../../forms/PreviousNameForm";
import PreviousAddressForm from "../../forms/PreviousAddressForm";
import LastFourFormWrapper from "../../forms/LastFourFormWrapper";
import IDForm from "../../forms/IDForm";
import AdditionalQuestionsForm from "../../forms/AdditionalQuestionsForm";
import PrimaryButton from "../buttons/PrimaryButton";
import Caption from "../typography/Caption";
import Paragraph from "../typography/Paragraph";
import Heading2 from "../typography/Heading1";
import EditIcon from "../icons/EditIcon";
import { makePhoneNumber } from "../../helpers";
import { updateRegistrant } from "../../store/registrant/registrantSlice";
import { formLabels, buttons } from '../../content';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

export const content = {
  "en-US": {
    personal_info_title: "Current Personal Information",
    residence_info_title: "Residence Information",
    previous_name_title: "Previous Name",
    previous_address_title: "Previous Address",
    license_title: "WI Drivers License or DOT ID",
    ssn_title: "Social Security Number",
    additional_info_title: "Additional Information",
    is_military: "I am a member of the military.",
    is_overseas: "I live permanently overseas with no intent to return to the United States.",
    poll_worker_interest: "I am interested in becoming a poll worker.",
    accommodations: "Accommodation needed at poll location:",
  },
  "es-ES": {
    personal_info_title: "Información personal actual",
    residence_info_title: "Información de residencia",
    previous_name_title: "Nombre anterior",
    previous_address_title: "Dirección anterior",
    license_title: "Licencia de conducir o ID emitida por el DOT de WI",
    ssn_title: "Número de seguro social",
    additional_info_title: "Información Adicional",
    is_military: "Soy un miembro del ejército.",
    is_overseas: "Vivo en el extranjero en forma permanente y no pretendo regresar a los Estados Unidos.",
    poll_worker_interest: "Estoy interesado en ser un trabajador electoral.",
    accommodations: "Adaptaciones necesarias en el centro electoral:",
  }
}

const MailInReview = ({ nextStep }) => {
  const dispatch = useDispatch();
  const registrant = useSelector((state) => state.registrant);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const [showPersonalInfoModal, setShowPersonalInfoModal] = React.useState(false);
  const [showResidenceInfoModal, setShowResidenceInfoModal] = React.useState(false);
  const [showPreviousNameModal, setShowPreviousNameModal] = React.useState(false);
  const [showPreviousAddressModal, setShowPreviousAddressModal] = React.useState(false);
  const [showLastFourSsnModal, setShowLastFourSsnModal] = React.useState(false);
  const [showIDModal, setShowIDModal] = React.useState(false);
  const [showAdditionQuestionsModal, setShowAdditionQuestionsModal] = React.useState(false);

  const {
    first_name,
    last_name,
    middle_name,
    suffix,
    date_of_birth_month,
    date_of_birth_day,
    date_of_birth_year,
    email_address,
    address_1,
    unit_type,
    unit_number,
    state,
    city,
    zipcode,
    has_mailing_address,
    mailing_address_type,
    mailing_address_1,
    mailing_address_2_type,
    mailing_address_2_number,
    mailing_city,
    mailing_state,
    mailing_region,
    mailing_zipcode,
    mailing_country,
    has_new_name,
    new_last_name,
    new_first_name,
    new_middle_name,
    new_suffix,
    has_new_address,
    new_address_1,
    new_address_2_type,
    new_address_2_number,
    new_city,
    new_state,
    new_zipcode,
    wi_license_id,
    wi_license_expiration_month,
    wi_license_expiration_day,
    wi_license_expiration_year,
    last_four_ssn,
    accommodations,
    is_military,
    is_overseas,
    is_interested_poll_worker,
    identity_type,
  } = registrant;

  const updateData = (data) => {
    dispatch(updateRegistrant(data));
    setShowPersonalInfoModal(false);
    setShowResidenceInfoModal(false);
    setShowPreviousNameModal(false);
    setShowPreviousAddressModal(false);
    setShowLastFourSsnModal(false);
    setShowIDModal(false);
    setShowAdditionQuestionsModal(false);
  };

  const closeModal = () => {
    setShowPersonalInfoModal(false);
    setShowResidenceInfoModal(false);
    setShowPreviousNameModal(false);
    setShowPreviousAddressModal(false);
    setShowLastFourSsnModal(false);
    setShowIDModal(false);
    setShowAdditionQuestionsModal(false);
  };

  return (
    <div>
      <FormWrapper>
        <div className="p-8 justify-start">
          <DataHeader
            label={content[currentLanguage].personal_info_title}
            edit={() => setShowPersonalInfoModal(true)}
          />
          <DataRow
            data={[
              {
                label: formLabels[currentLanguage].last_name,
                value: has_new_name ? new_last_name : last_name,
              },
              {
                label: formLabels[currentLanguage].first_name,
                value: has_new_name ? new_first_name : first_name,
              },
            ]}
          />
          <DataRow
            data={[
              {
                label: formLabels[currentLanguage].middle_name,
                value: has_new_name ? new_middle_name : middle_name,
              },
              {
                label: formLabels[currentLanguage].suffix_eg,
                value: has_new_name ? new_suffix : suffix,
              },
            ]}
          />
          <DataRow
            data={[
              {
                label: formLabels[currentLanguage].date_of_birth,
                value: `${date_of_birth_month}/${date_of_birth_day}/${date_of_birth_year}`,
              },
            ]}
          />
          <DataRow
            data={[
              {
                label: formLabels[currentLanguage].phone_number,
                value: `${makePhoneNumber(registrant)}`,
              },
              {
                label: formLabels[currentLanguage].email_address,
                value: email_address
              },
            ]}
          />
        </div>
      </FormWrapper>

      <FormWrapper>
        <div className="p-8 justify-start">
          <DataHeader
            label={content[currentLanguage].residence_info_title}
            edit={() => setShowResidenceInfoModal(true)}
          />
          <DataRow
            data={[
              {
                label: formLabels[currentLanguage].address_1_short,
                value: has_new_address ? new_address_1 : address_1,
              },
            ]}
          />
          <DataRow
            data={[
              {
                label: formLabels[currentLanguage].apt_type,
                value: has_new_address ? new_address_2_type : unit_type,
              },
              {
                label: formLabels[currentLanguage].apt_number,
                value: has_new_address
                  ? new_address_2_number
                  : unit_number,
              },
            ]}
          />
          <DataRow
            data={[
              {
                label: formLabels[currentLanguage].city,
                value: has_new_address ? new_city : city
              },
              {
                label: formLabels[currentLanguage].state,
                value: has_new_address ? new_state : state,
              },
            ]}
          />
          <DataRow
            data={[
              {
                label: formLabels[currentLanguage].zipcode,
                value: has_new_address ? new_zipcode : zipcode,
              },
            ]}
          />
          {has_mailing_address && (
            <>

              <div className="w-full flex flex-row mb-6 items-center">
                <Checkbox.Root
                  disabled={true}
                  checked={true}
                  className="shadow-sm mr-4 flex-shrink-0 flex h-[25px] border-[#13187C] border-2 w-[25px] appearance-none items-center justify-center rounded-[4px] bg-white outline-none"
                >
                  <Checkbox.Indicator>
                    <CheckIcon className='w-auto h-[25px]' />
                  </Checkbox.Indicator>
                </Checkbox.Root>
                <Paragraph size="base">
                  {formLabels[currentLanguage].i_have_mailing_address}
                </Paragraph>
              </div>
              <div className="w-full flex flex-row mb-6">
                <div className="flex-1 flex flex-col">
                  <FieldLabel>
                    {mailing_address_type == "po"
                      ? formLabels[currentLanguage].pobox_mailing_address_1
                      : formLabels[currentLanguage].mailing_address
                    }
                  </FieldLabel>
                  <FieldValue>{mailing_address_1}</FieldValue>
                </div>
              </div>
              {mailing_address_type != "po" && (
                <div className="w-full flex flex-row mb-6">
                  {mailing_address_type == "us" && (
                    <div className="flex-1 flex flex-col">
                      <FieldLabel>{formLabels[currentLanguage].apt_type}</FieldLabel>
                      <FieldValue>{mailing_address_2_type}</FieldValue>
                    </div>
                  )}
                  <div className={`flex-1 flex flex-col ${mailing_address_type == "us" ? 'ml-4' : ''}`}>
                    <FieldLabel>
                      {mailing_address_type == "us"
                        ? formLabels[currentLanguage].apt_number
                        : formLabels[currentLanguage].mailing_address_2}
                    </FieldLabel>
                    <FieldValue>{mailing_address_2_number}</FieldValue>
                  </div>
                </div>
              )}
              <div className="w-full flex flex-row mb-6">
                <div className="flex-1 flex flex-col">
                  <FieldLabel>{formLabels[currentLanguage].city}</FieldLabel>
                  <FieldValue>{mailing_city}</FieldValue>
                </div>
                {mailing_address_type == "foreign" ? (
                  <div className="flex-1 flex flex-col ml-4">
                    <FieldLabel>{formLabels[currentLanguage].mailing_region}</FieldLabel>
                    <FieldValue>{mailing_region}</FieldValue>
                  </div>
                ) : (
                  <div className="flex-1 flex flex-col ml-4">
                    <FieldLabel>{formLabels[currentLanguage].state}</FieldLabel>
                    <FieldValue>{mailing_state}</FieldValue>
                  </div>
                )}
              </div>
              <div className="w-full flex flex-row mb-6">
                <div className="flex-1 flex flex-col">
                  <FieldLabel>
                    {mailing_address_type != "foreign"
                      ? formLabels[currentLanguage].zipcode
                      : formLabels[currentLanguage].mailing_zipcode
                    }
                  </FieldLabel>
                  <FieldValue>{mailing_zipcode}</FieldValue>
                </div>
                <div className="flex-1 flex flex-col ml-4">
                  {mailing_address_type == "foreign" && (
                    <>
                      <FieldLabel>{formLabels[currentLanguage].country}</FieldLabel>
                      <FieldValue>{mailing_country}</FieldValue>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </FormWrapper>

      {has_new_name && (
        <FormWrapper>
          <div className="p-8 justify-start">
            <DataHeader
              label={content[currentLanguage].previous_name_title}
              edit={() => setShowPreviousNameModal(true)}
            />
            <DataRow
              data={[
                { label: formLabels[currentLanguage].last_name, value: last_name },
                { label: formLabels[currentLanguage].first_name, value: first_name },
              ]}
            />
            <DataRow
              data={[
                { label: formLabels[currentLanguage].middle_name, value: middle_name },
                { label: formLabels[currentLanguage].suffix_eg, value: suffix },
              ]}
            />
          </div>
        </FormWrapper>
      )}

      {has_new_address && (
        <FormWrapper>
          <div className="p-8 justify-start">
            <DataHeader
              label={content[currentLanguage].previous_address_title}
              edit={() => setShowPreviousAddressModal(true)}
            />
            <DataRow data={[{ label: formLabels[currentLanguage].previous_address, value: address_1 }]} />
            <DataRow
              data={[
                { label: formLabels[currentLanguage].previous_type, value: unit_type },
                { label: formLabels[currentLanguage].previous_number, value: unit_number },
              ]}
            />
            <DataRow
              data={[
                { label: formLabels[currentLanguage].previous_city, value: city },
                { label: formLabels[currentLanguage].previous_state, value: state },
              ]}
            />
            <DataRow
              data={[
                { label: formLabels[currentLanguage].previous_zipcode, value: zipcode },
              ]}
            />
          </div>
        </FormWrapper>
      )}

      {identity_type == "has_id" && (
        <FormWrapper>
          <div className="p-8 justify-start">
            <DataHeader
              label={content[currentLanguage].license_title}
              edit={() => setShowIDModal(true)}
            />
            <DataRow
              data={[
                {
                  label: formLabels[currentLanguage].wi_license_id,
                  value: wi_license_id,
                  caption: formLabels[currentLanguage].wi_license_id_caption,
                },
              ]}
            />
            <DataRow
              data={[
                {
                  label: formLabels[currentLanguage].id_expiration_date,
                  value: `${wi_license_expiration_month}/${wi_license_expiration_day}/${wi_license_expiration_year}`,
                },
              ]}
            />
          </div>
        </FormWrapper>
      )}

      {identity_type == "has_ssn" && (
        <FormWrapper>
          <div className="p-8 justify-start">
            <DataHeader
              label={content[currentLanguage].ssn_title}
              edit={() => setShowLastFourSsnModal(true)}
            />
            <DataRow
              data={[
                {
                  label: formLabels[currentLanguage].last_four_ssn_long,
                  value: `XXX-XX-${last_four_ssn}`,
                },
              ]}
            />
          </div>
        </FormWrapper>
      )}

      <FormWrapper>
        {!showAdditionQuestionsModal && (
          <div className="p-8 justify-start">
            <DataHeader
              label={content[currentLanguage].additional_info_title}
              edit={() => setShowAdditionQuestionsModal(true)}
            />
            <div className="py-3 px-6 mb-4 pr-8">
              <div className="flex w-full flex-row mb-3 items-center">

                <Checkbox.Root
                  disabled={true}
                  checked={is_military}
                  className="opacity-75 shadow-sm mr-4 flex-shrink-0 flex h-[25px] border-[#13187C] border-2 w-[25px] appearance-none items-center justify-center rounded-[4px] bg-white outline-none"
                >
                  <Checkbox.Indicator>
                    <CheckIcon className='w-auto h-[25px]' />
                  </Checkbox.Indicator>
                </Checkbox.Root>
                <Paragraph pr="4">{content[currentLanguage].is_military}</Paragraph>
              </div>
              <div className="flex w-full flex-row mb-3 items-center">
                <Checkbox.Root
                  disabled={true}
                  checked={is_overseas}
                  className="opacity-75 shadow-sm mr-4 flex-shrink-0 flex h-[25px] border-[#13187C] border-2 w-[25px] appearance-none items-center justify-center rounded-[4px] bg-white outline-none"
                >
                  <Checkbox.Indicator>
                    <CheckIcon className='w-auto h-[25px]' />
                  </Checkbox.Indicator>
                </Checkbox.Root>
                <Paragraph>
                  {content[currentLanguage].is_overseas}
                </Paragraph>
              </div>
              <div className="flex w-full flex-row mb-3 items-center">
                <Checkbox.Root
                  disabled={true}
                  checked={is_interested_poll_worker}
                  className="opacity-75 shadow-sm mr-4 flex-shrink-0 flex h-[25px] border-[#13187C] border-2 w-[25px] appearance-none items-center justify-center rounded-[4px] bg-white outline-none"
                >
                  <Checkbox.Indicator>
                    <CheckIcon className='w-auto h-[25px]' />
                  </Checkbox.Indicator>
                </Checkbox.Root>
                <Paragraph>
                  {content[currentLanguage].poll_worker_interest}
                </Paragraph>
              </div>
            </div>
            <DataRow
              data={[
                {
                  label: content[currentLanguage].accommodations,
                  value: accommodations,
                },
              ]}
            />
          </div>
        )}
      </FormWrapper>

      <div className="px-1 mb-4">
        <div className="flex w-full flex-row mt-4 items-center justify-end" >
          <div className="ml-4">
            <PrimaryButton onClick={nextStep} title={buttons[currentLanguage].next_step} />
          </div>
        </div>
      </div>
      {/* Personal Information Modal */}
      <ModalContainer
        isOpen={showPersonalInfoModal}
        onClose={() => setShowPersonalInfoModal(false)}
      >
        <PersonalInformationForm onSubmit={updateData} onCancel={closeModal} />
      </ModalContainer>
      {/* Residence Information Modal */}
      <ModalContainer
        isOpen={showResidenceInfoModal}
        onClose={() => setShowResidenceInfoModal(false)}
      >
        <ResidenceInformationForm onSubmit={updateData} onCancel={closeModal} />
      </ModalContainer>
      {/* Previous Name Modal */}
      <ModalContainer
        isOpen={showPreviousNameModal}
        onClose={() => setShowPreviousNameModal(false)}
      >
        <PreviousNameForm onSubmit={updateData} onCancel={closeModal} />
      </ModalContainer>
      {/* Previous Address Modal */}
      <ModalContainer
        isOpen={showPreviousAddressModal}
        onClose={() => setShowPreviousAddressModal(false)}
      >
        <PreviousAddressForm onSubmit={updateData} onCancel={closeModal} />
      </ModalContainer>
      {/* Last Four Modal */}
      <ModalContainer
        isOpen={showLastFourSsnModal}
        onClose={() => setShowLastFourSsnModal(false)}
      >
        <LastFourFormWrapper onSubmit={updateData} onCancel={closeModal} />
      </ModalContainer>
      {/* ID Modal */}
      <ModalContainer
        isOpen={showIDModal}
        onClose={() => setShowIDModal(false)}
      >
        <IDForm onSubmit={updateData} onCancel={closeModal} />
      </ModalContainer>
      {/* Additional Questions Modal */}
      <ModalContainer
        isOpen={showAdditionQuestionsModal}
        onClose={() => setShowAdditionQuestionsModal(false)}
      >
        <AdditionalQuestionsForm onSubmit={updateData} onCancel={closeModal} />
      </ModalContainer>
    </div>
  );
};

export default MailInReview;

const FieldLabel = ({ children }) => {
  return (
    <span className="font-heading uppercase text-sm text-[#13187C] font-black">
      {children}
    </span>
  );
};

const FieldValue = ({ children }) => {
  return (
    <span className="font-body text-[#13187C] text-lg">
      {children}
    </span>
  );
};

const DataRow = ({ data }) => {
  const dataList = data.map(({ label, value, caption }) => {
    return (
      <div className="w-full flex flex-col" key={`${value}-${label}`} flex={1}>
        <FieldLabel>{label}</FieldLabel>
        {caption && <Caption fontStyle={"italic"}>{caption}</Caption>}
        <FieldValue>{value}</FieldValue>
      </div>
    );
  });
  return (
    <div className="w-full flex flex-row mb-6">
      {dataList}
    </div>
  );
};

const DataHeader = ({ label, edit }) => {
  return (
    <div className="flex w-full flex-row mb-4 justify-start">
      <div className="flex-1">
        <Heading2 textTransform={"uppercase"} color={"red"}>
          {label}
        </Heading2>
      </div>
      <div>
        <button type="button" onClick={edit}>
          <EditIcon />
        </button>
      </div>
    </div>
  );
};
