import React from 'react';
import PropTypes from 'prop-types';

const Heading1 = ({ children, centered, ...props }) => {
    const {color} = props;
    const classes = `uppercase font-heading text-[#13187C] font-black text-xl lg:text-2xl my-1 ${centered ? 'text-center' : 'text-left'} ${color? 'text-[#B80F00]' : ''}`;
    return <h2 className={classes}>{children}</h2>
};

Heading1.propTypes = {
    children: PropTypes.node.isRequired
};

export default Heading1;
