import './InputText.scss';

import React from 'react';
import InputLabel from './InputLabel';
import InputError from './InputError';
import InputMask from "react-input-mask";

const maskTypes = {
    dob: "99/99/9999",
    phone: "999-999-9999",
    zipcode: "99999",
    ssn: "9999",
    id: "a***-****-****-**"
};

const InputText = React.forwardRef((props, ref) => {
    const { label, errors, name, required, maskType, placeholder, onChange, onBlur, onFocus } = props;

    const inputStyleProps = {
        borderWidth: '2px',
        borderColor: '#13187C',
        borderRadius: 50,
        bg: 'white',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,

    };

    return (
        <div className="flex flex-col p-2 w-full">
            {label &&
                <InputLabel isRequired={required}>
                    {label}
                </InputLabel>
            }
            {!maskType ? (
                <input
                    ref={ref}
                    name={name}
                    fontSize={'base'}
                    type="text"
                    value={props.value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    className={`border-2 ${name in errors ? 'input--error' : 'border-[#13187C]'} rounded-full p-2 focus:ring-offset-0 focus:ring-0 ring-offset-0 ring-0`}
                    style={{ ...inputStyleProps }}
                />
            ) : (
                <InputMask
                    ref={ref}
                    name={name}
                    id={name + '-input'}
                    className={`input--masked ${name in errors ? 'input--error' : ''}`}
                    value={props.value}
                    placeholder={placeholder}
                    mask={maskTypes[maskType]}
                    style={{ ...inputStyleProps }}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
            )}
            {name in errors && <InputError>{errors[name]}</InputError>}
        </div>
    )
});

export default InputText
