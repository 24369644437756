import React from 'react';

const Wrapper = ({ children }) => {
    return (
        <div className="bg-gray--left">
            <div className="w-full flex flex-col lg:flex-row justify-between lg:max-w-[80%] mx-auto lg:py-20">
                {children}
            </div>
        </div>
    )
};

export default Wrapper;
