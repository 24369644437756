import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from "../components/layout/Wrapper";
import Paragraph from '../components/typography/Paragraph';
import Heading1 from '../components/typography/Heading1';
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import NewNameForm from "../forms/NewNameForm";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { useNavigate } from "react-router-dom";

export const content = {
    "en-US": {
        heading: "Please enter your new name.",
        paragraph: "Please enter your new name as it is registered with the Social Security Administration.",
    },
    "es-ES": {
        heading: "Ingrese su nuevo nombre.",
        paragraph: "Ingrese su nuevo nombre tal cual está registrado en la Administración del Seguro Social.",
    }
};

function UpdateNameFormPage({ navigation }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const registrant = useSelector((state) => state.registrant);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);

    const nextStep = (data) => {
        dispatch(updateRegistrant(data));

        const {
            information_correct
        } = registrant;

        if (information_correct == 'update_name_address') {
            navigate('/update-address');
        } else {
            navigate('/dob-consent');
        }
    };

    React.useEffect(() => {
        dispatch(changeStep([2, 6]));
    }, []);

    return (
        <Wrapper>
            <ContentLeft>
                <Heading1 mb={4}>{content[currentLanguage].heading}</Heading1>
                <Paragraph mb={4}>{content[currentLanguage].paragraph}</Paragraph>
            </ContentLeft>
            <ContentRight>
                <NewNameForm onSubmit={nextStep} />
            </ContentRight>
        </Wrapper>
    );
}

export default UpdateNameFormPage;
